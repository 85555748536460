import { useContext, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { ParentDashboardContext } from "src/contexts/ParentDashboardContext";

const StudentNavSelect = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { studentsList, selectedStudentIndex, setSelectedStudentIndex } =
    useContext(ParentDashboardContext);
  const currentStudent =
    studentsList.length > selectedStudentIndex
      ? studentsList[selectedStudentIndex]
      : {};

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle tag="div" style={{ cursor: "pointer" }} caret>
        <img
          className={"me-2 border rounded-circle"}
          height={40}
          width={40}
          src={currentStudent.imageUrl}
          alt="student"
        />
        {currentStudent.fullName}
      </DropdownToggle>
      <DropdownMenu style={{ width: "330px" }}>
        {studentsList.map((student, i) => (
          <DropdownItem
            onClick={() => setSelectedStudentIndex(i)}
            key={student.id}
            value={i}
            className={
              "px-3 btn btn-default border-bottom w-100 py-2 d-flex align-items-center"
            }
          >
            <img
              className={"me-2 border rounded-circle"}
              width={30}
              height={30}
              src={student.imageUrl}
              alt="student"
            />
            {student.fullName}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};
export default StudentNavSelect;
