import moment from "moment-timezone";
import { weekDays } from "../constants/weekDays";
import { documentId, getDocs, query, where } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import {
  isGroupClassEvent,
  isPackagePrivateLesson,
  isRecurringEvent,
  MAX_ITEMS_IN_PACKAGE_LESSON_SET,
} from "src/constants/eventsEnum";
import {
  isAbsenceBypassMakeupDeadline,
  isApprovedAbsence,
  isGroupClassAbsence,
  isPartiallyApprovedAbsence,
  isPerDurationAbsence,
  isPerLessonAbsence,
  isTeacherAbsence,
  makeupLimitAbsenceTypes,
} from "src/constants/absenceTypes";
import {
  hasAdminCredentials,
  isAdmin,
  isStudent,
  isSuperAdmin,
  isTeacher,
  UserRole,
} from "src/constants/UserRoleEnum";
import _ from "lodash";
import { absenceMakeUpStatuses } from "src/constants/absenceMakeUpStatuses";
import { usersDefaultStorageCapacity } from "src/constants/storageCapacity";
import { parseFirebaseDoc } from "./getFirebaseDoc";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import {
  POSITIVE_5_GMT,
  POSITIVE_6_GMT,
  POSITIVE_7_GMT,
  POSITIVE_8_GMT,
  POSITIVE_9_GMT,
} from "src/constants/timezones";
import { CURRENT_TZ_LS_PROP_NAME } from "src/constants/constantVariables";
import { adminsExtraFunctions } from "src/constants/usersExtraFunctions";
import {
  fullTimeAdminPaymentProps,
  fullTimeAdminRates,
  fullTimeTeacherPaymentProps,
  fullTimeTeacherRates,
  partTimeAdminPaymentProps,
  partTimeAdminRates,
  partTimeTeacherPaymentProps,
  partTimeTeacherRates,
  paymentCalculationTypes,
  paymentDatesFormat,
  paymentDatesTimezone,
  paymentsMap,
  workConditions,
} from "src/constants/paymentsEnum";
import {
  groupClassEnrollmentTypes,
  isRegularGroupClassEnrollment,
} from "src/constants/groupClassEnrollmentsConstants";
import {
  concertInvitationStatuses,
  isTeacherRecommendationConcert,
} from "src/constants/concertEnum";

const MINUTES_IN_DAY = 1440;

export const downloadSingleItem = async (downloadUrl, title) => {
  const toastId = {};
  toast.info("Your download will start shortly", { autoClose: 5000 });
  const response = await fetch(downloadUrl);
  const contentLength = response.headers.get("content-length");
  const total = parseInt(contentLength, 10);
  let loaded = 0;
  const res = new Response(
    new ReadableStream({
      async start(controller) {
        const reader = response.body.getReader();
        toastId.current = toast.info("Downloaded: 0%");
        for (;;) {
          const { done, value } = await reader.read();
          if (done) break;
          loaded += value.byteLength;
          toast.update(toastId.current, {
            render: `Downloaded: ${((100 * loaded) / total).toFixed(2)}%`,
            autoClose: false,
            type: "info",
          });
          controller.enqueue(value);
        }
        console.log("step 3");
        toast.update(toastId.current, {
          render: `Download completed: 100.00%, pick a save location`,
          autoClose: 3000,
          type: "success",
        });
        controller.close();
      },
    })
  );
  const blob = await res.blob();
  const url = URL.createObjectURL(blob);
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = title;
  anchor.click();
};

export const convertTimelineArrayToJSDates = (timeline) => {
  return timeline.map((timelineItem) => ({
    ...timelineItem,
    startDate: timelineItem.startDate.toDate(),
    lastDate: timelineItem?.lastDate
      ? timelineItem.lastDate.toDate()
      : undefined,
  }));
};
export const getCurrentTimeline = (timeline) => {
  const convertedTimeLine = convertTimelineArrayToJSDates(timeline);
  return getRecurringEventTimelineObj({
    timeline: convertedTimeLine,
    requestDate: updatedMomentNow()
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .toDate(),
    startDateAccessor: "startDate",
    lastDateAccessor: "lastDate",
    withApproximation: true,
  });
};
export const getStudents = async (isAdmin, isTeacher, firebase, userId) => {
  if (!isTeacher && !isAdmin) {
    return;
  }
  const studentsCollection = await getDocs(
    firebase.filterUsersByRole(UserRole.STUDENT)
  );
  const studentsArray = [];
  if (isTeacher) {
    // const lessons = await getDocs(firebase.getTeacherLessons(userId));
    const privateLessons = await getDocs(
      firebase.getTeacherPrivateLessons(userId)
    );

    const studentsIdsArray = [];
    // lessons.forEach((l) => {
    //   const studentId = l.data().studentId;
    //   console.log(l.id)
    //   if (studentsIdsArray.indexOf(studentId) === -1) {
    //     studentsIdsArray.push(studentId);
    //   }
    // });
    privateLessons.forEach((l) => {
      const { studentId, timeline, type } = l.data();
      const isPackageLsn = isPackagePrivateLesson(type);

      if (isPackageLsn) {
        if (studentsIdsArray.indexOf(studentId) === -1) {
          studentsIdsArray.push(studentId);
        }
      } else {
        const currentItem = getCurrentTimeline(timeline);
        if (currentItem?.teacherId === userId) {
          if (studentsIdsArray.indexOf(studentId) === -1) {
            studentsIdsArray.push(studentId);
          }
        }
      }
    });

    if (studentsIdsArray.length) {
      const studentIdsChunks = chunkArrayInGroups(studentsIdsArray, 10);
      let requests = [];
      studentIdsChunks.forEach((studentIdsArray) => {
        const studentsDocRef = firebase.users();
        const studentsQuery = query(
          studentsDocRef,
          where(documentId(), "in", studentIdsArray)
        );
        const req = getDocs(studentsQuery);
        requests.push(req);
      });
      const studentsSnapshotsChunks = await Promise.all(requests);
      const studentsChunks = studentsSnapshotsChunks.map((snap) =>
        parseFirebaseDoc(snap.docs)
      );
      const students = studentsChunks.flat();

      students.forEach((student) => {
        studentsArray.push(student);
      });
    }
  } else {
    studentsCollection.forEach((student) => {
      studentsArray.push({
        id: student.id,
        ...student.data(),
      });
    });
  }
  return studentsArray.sort((a, b) => a.fullName.localeCompare(b.fullName));
};

export const filterLessonsByLocation = (lessons, selectedLocations) => {
  let filteredLessons = {};
  if (selectedLocations.length === 0) {
    // filteredLessons = { ...lessons };
    filteredLessons = {};
  } else {
    Object.keys(lessons).forEach((lessonID) => {
      const lessonLocations = getPrivateLessonUniqueLocationsIds(
        lessons[lessonID]
      );
      for (let location of selectedLocations) {
        if (lessonLocations.includes(location.value)) {
          filteredLessons[lessonID] = lessons[lessonID];
        }
      }
    });
  }
  return filteredLessons;
};
export const filterStudentsByLessons = (students, filteredLessons) => {
  filteredLessons = filteredLessons || {};
  if (!students?.length || !Object.keys(filteredLessons).length) return [];

  const lessonsUniqueStudentsIds = [
    ...new Set(
      Object.values(filteredLessons).map(({ studentId }) => studentId)
    ),
  ];
  const filteredStudents = [];
  for (let i = 0; i < students.length; i++) {
    if (lessonsUniqueStudentsIds.includes(students[i].id)) {
      filteredStudents.push(students[i]);
    }
  }
  return filteredStudents;
};
export const getUncompletePrivateStudents = (students, lessons) => {
  lessons = lessons || {};
  if (!students?.length) return [];

  if (!Object.keys(lessons).length) return students;

  const lessonsUniqueStudentsIds = [
    ...new Set(Object.values(lessons).map(({ studentId }) => studentId)),
  ];

  const incompleteStudents = [];
  for (const student of students) {
    if (!lessonsUniqueStudentsIds.includes(student.id)) {
      incompleteStudents.push(student);
    }
  }
  return incompleteStudents;
};

// Returns a difference between 2 dates in minutes
export const getTimeDiffInMins = (startTime, endTime) => {
  const start = moment(startTime).set({ seconds: 0, milliseconds: 0 });
  const end = moment(endTime).set({ seconds: 0, milliseconds: 0 });

  const duration = moment.duration(end.diff(start));
  const mins = duration.asMinutes();
  return mins;
};
// Returns an array with the available time slots in a day within the teachers working hours
// Accepts the date in any format as long as its a valid date, because it will be passed to moment in the function anyways
// ex parameters
// const duration = 15; //lesson duration In Mins
// const workStartTime = "2018-05-12T09:00:00.000Z";
// const workEndTime = "2018-05-12T22:00:00.000Z";
// const busySlots = [
//   {
//     start_time: "2018-05-12T10:00:00.000Z",
//     end_time: "2018-05-12T11:00:00.000Z",
//   },
// ];
export const getAvailableTimeSlots = (
  workStartTime,
  workEndTime,
  busySlots,
  duration,
  allowPastDates = false
) => {
  if (!workStartTime || !workEndTime || !busySlots || !duration)
    return { error: "Invalid Parameters", isValid: false };

  const {
    isStartTimeBeforeEndTime,
    isValid,
    areCorrectOrder,
    isSameDay,
    areValidBusySlots,
    arePresentOrFutureDates,
  } = validateDates(workStartTime, workEndTime, busySlots);

  if (!busySlots.length) {
    if (isStartTimeBeforeEndTime) {
      return [
        {
          start_time: new Date(workStartTime),
          end_time: new Date(workEndTime),
        },
      ];
    } else {
      return { error: "Start Time shouldnt be after the End Time", isValid };
    }
  }

  ///////////////////////////////////////////////////////////////////
  if (!isSameDay) return { error: "Dates are not in the same day", isValid };
  if (!areCorrectOrder)
    return {
      error:
        "Invalid dates, make sure busySlots are within the workStartDate and workEndDate",
      isValid,
    };
  if (!areValidBusySlots)
    return { error: "Busy slots are overlapping", isValid };
  if (!arePresentOrFutureDates && !allowPastDates)
    return { error: "Please enter future/present dates", isValid };

  duration = parseInt(duration);

  // arrange busySlots ascendingly (old is first)
  const arrangedBusySlots = busySlots
    .map((slot) => ({
      ...slot,
      start_time: moment(slot.start_time).set({ second: 0, millisecond: 0 }),
      end_time: moment(slot.end_time).set({ second: 0, millisecond: 0 }),
    }))
    .sort((a, b) => a.start_time - b.start_time)
    .map((slot) => ({
      ...slot,
      start_time: moment(slot.start_time)
        .set({ second: 0, millisecond: 0 })
        .toDate(),
      end_time: moment(slot.end_time)
        .set({ second: 0, millisecond: 0 })
        .toDate(),
    }));
  workStartTime = new Date(workStartTime);
  workEndTime = new Date(workEndTime);

  let freeSlots = [];

  let beginAt = workStartTime;
  const overAt = workEndTime;
  let count = 0;
  const last = arrangedBusySlots.length;
  arrangedBusySlots.forEach((item) => {
    // Gets the time between 2 dates in mins
    const diff = getTimeDiffInMins(beginAt, item.start_time);
    if (diff >= duration) {
      // diff >= duration means we have an available slot
      let free = { start_time: beginAt, end_time: item.start_time };
      freeSlots.push(free);
      beginAt = item.end_time;
      count += 1;

      // Process for end slot
      if (last === count) {
        const diff = getTimeDiffInMins(item.end_time, overAt);
        if (diff >= duration) {
          const free = { start_time: item.end_time, end_time: overAt };
          freeSlots.push(free);
        }
      }
      // Process for end slot
    } else {
      beginAt = item.end_time;
      count += 1;

      // Process for end slot
      if (last === count) {
        const diff = getTimeDiffInMins(item.end_time, overAt);
        if (diff >= duration) {
          const free = { start_time: item.end_time, end_time: overAt };
          freeSlots.push(free);
        }
      }
      // Process for end slot
    }
  });

  return freeSlots;
};
// divides an array into chunks (Useful when using firebase query with "in" and "array-contains" with large arrays)
export const chunkArrayInGroups = (arr = [], size) => {
  let myArray = [];
  for (let i = 0; i < arr.length; i += size) {
    myArray.push(arr.slice(i, i + size));
  }
  return myArray;
};

export const instrumentsToString = (instrumentsNamesArr) => {
  if (!instrumentsNamesArr?.length) return "";
  let instrumentsString = "";
  instrumentsNamesArr?.forEach((instrument, i) => {
    if (i === 0) {
      instrumentsString = instrument;
    } else {
      instrumentsString = `${instrumentsString}, ${instrument}`;
    }
  });
  return instrumentsString;
};

export const availableDaysToString = (teacherDays = []) => {
  let teachingDaysString = "N/A";
  const teacherDaysWeekDays = teacherDays
    ?.map((day) => {
      const { startDate, endDate } = day;
      const startDateWeekDay = moment(startDate).day();
      const endDateWeekDay = moment(endDate).day();

      return [startDateWeekDay, endDateWeekDay];
    })
    .flat();
  const uniqueWeekDays = [...new Set(teacherDaysWeekDays || [])];
  if (uniqueWeekDays?.length) {
    uniqueWeekDays.forEach((day, i) => {
      if (i === 0) {
        teachingDaysString = weekDays.find(
          (weekDay) => weekDay.id === parseInt(day)
        ).shortName;
      } else {
        teachingDaysString = `${teachingDaysString}, ${
          weekDays.find((weekDay) => weekDay.id === parseInt(day)).shortName
        }`;
      }
    });
  }
  return teachingDaysString;
};

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const combineArrayToStr = (arr = []) => {
  if (!arr.length) return "";
  let combinedStr = "";
  arr.forEach((item, i) => {
    if (i === 0) {
      combinedStr = item;
    } else {
      combinedStr += `, ${item}`;
    }
  });
  return combinedStr;
};

const validateDates = (workStartTime, workEndTime, busySlots = []) => {
  let isStartTimeBeforeEndTime = true;
  let isSameDay = true;
  let areCorrectOrder = true;
  let areValidBusySlots = true;
  let arePresentOrFutureDates = true;

  const momentStart = moment(workStartTime).set({ second: 0, millisecond: 0 });
  const momentEnd = moment(workEndTime).set({ second: 0, millisecond: 0 });
  const momentBusySlots = busySlots.map((slot) => ({
    ...slot,
    start_time: moment(slot?.start_time).set({ second: 0, millisecond: 0 }),
    end_time: moment(slot?.end_time).set({ second: 0, millisecond: 0 }),
  }));

  const startAndEndDiff = getTimeDiffInMins(momentStart, momentEnd);
  if (startAndEndDiff <= 0) {
    isStartTimeBeforeEndTime = false;
  }

  for (const busySlot of momentBusySlots) {
    // Check if dates are in the same day
    if (
      !momentStart.isSame(momentEnd, "day") ||
      !momentStart.isSame(busySlot.start_time, "day") ||
      !momentStart.isSame(busySlot.end_time, "day")
    ) {
      isSameDay = false;
      break;
    }

    // checks if teacher activity is within the work day start and end time
    if (
      !momentStart.isSameOrBefore(busySlot.start_time) ||
      !momentEnd.isSameOrAfter(busySlot.end_time) ||
      !busySlot.start_time.isBefore(busySlot.end_time)
    ) {
      areCorrectOrder = false;
      break;
    }
    const momToday = updatedMomentNow().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    if (
      !momentStart.isSameOrAfter(momToday) ||
      !momentEnd.isSameOrAfter(momToday) ||
      !busySlot.start_time.isSameOrAfter(momToday) ||
      !busySlot.end_time.isSameOrAfter(momToday)
    ) {
      arePresentOrFutureDates = false;
    }
  }

  // Checks if busySlots overlap
  const busySlotsTimeArr = momentBusySlots.map((busySlot) => [
    busySlot.start_time.format("HH:mm"),
    busySlot.end_time.format("HH:mm"),
  ]);
  if (isOverlappingTime(busySlotsTimeArr)) areValidBusySlots = false;

  return {
    isStartTimeBeforeEndTime,
    isSameDay,
    areCorrectOrder,
    areValidBusySlots,
    arePresentOrFutureDates,
    isValid:
      isStartTimeBeforeEndTime &&
      isSameDay &&
      areCorrectOrder &&
      areValidBusySlots &&
      arePresentOrFutureDates,
  };
};
// shortens the available time slots to be near the user activities (busySlots)
export const getAvailableSlotsNearActivities = (
  availableSlotsOutput,
  busySlots,
  timeInMins = 60
) => {
  if (!busySlots || !availableSlotsOutput) return;

  if (!busySlots.length) return availableSlotsOutput;

  timeInMins = parseInt(timeInMins);

  let shortenedAvailableTimeSlots = [];
  availableSlotsOutput.forEach((availableSlot) => {
    for (const busySlot of busySlots) {
      // calculations for the time before the activity
      const firstTimeDiff = getTimeDiffInMins(
        availableSlot.start_time,
        busySlot.start_time
      );
      const timeBeforeActivity = moment(busySlot.start_time).subtract(
        firstTimeDiff > timeInMins ? timeInMins : firstTimeDiff,
        "minutes"
      );
      const isTimeBeforeActivityAvailable =
        timeBeforeActivity.isBetween(
          availableSlot.start_time,
          availableSlot.end_time,
          undefined,
          "[]"
        ) && moment(busySlot.start_time).isSame(availableSlot.end_time);

      if (isTimeBeforeActivityAvailable) {
        const newSlot = {
          start_time: timeBeforeActivity.toDate(),
          end_time: busySlot.start_time,
        };
        shortenedAvailableTimeSlots.push(newSlot);
      }

      // calculations for the time after the activity
      const secondTimeDiff = getTimeDiffInMins(
        busySlot.end_time,
        availableSlot.end_time
      );
      const momTimeAfterActivity = moment(busySlot.end_time).add(
        secondTimeDiff > timeInMins ? timeInMins : secondTimeDiff,
        "minutes"
      );
      const isTimeAFterActivityAvailable =
        momTimeAfterActivity.isBetween(
          availableSlot.start_time,
          availableSlot.end_time,
          undefined,
          "[]"
        ) && moment(busySlot.end_time).isSame(availableSlot.start_time);
      if (isTimeAFterActivityAvailable) {
        const newSlot = {
          start_time: busySlot.end_time,
          end_time: momTimeAfterActivity.toDate(),
        };
        shortenedAvailableTimeSlots.push(newSlot);
      }
    }
  });
  //filters the duplicates
  return shortenedAvailableTimeSlots.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          moment(t.start_time).isSame(value.start_time) &&
          moment(t.end_time).isSame(value.end_time)
      )
  );
};
// returns true if there are overlapping time , false if not
//ex param:
// [
//   ["03:00", "04:00"],
//   ["02:00", "07:00"],
//   ["12:00", "15:00"]
// ];
const isOverlappingTime = (timeSegments) => {
  if (timeSegments.length === 1) return false;

  timeSegments.sort((timeSegment1, timeSegment2) =>
    timeSegment1[0].localeCompare(timeSegment2[0])
  );

  for (let i = 0; i < timeSegments.length - 1; i++) {
    const currentEndTime = timeSegments[i][1];
    const nextStartTime = timeSegments[i + 1][0];

    if (currentEndTime > nextStartTime) {
      return true;
    }
  }

  return false;
};

// Divide the time between 2 dates into chunks in this format [[12:00, 13:00] , [13:00: 14:00]]
export const getTimeChunks = (
  startTime,
  endTime,
  duration = 30,
  step = 15,
  format24Hr = true
) => {
  if ((!startTime, !endTime)) return [];
  const momentFormat = format24Hr ? "HH:mm" : "hh:mm A";
  let resultArr = [];
  const momStart = moment(startTime);
  const momEnd = moment(endTime);
  let index = 0;

  while (momStart < momEnd) {
    if (index > 10000) break;
    const start = momStart.add(index === 0 ? 0 : step, "minutes");

    const end = moment(momStart).add(duration, "minutes");
    if (end.isSameOrBefore(momEnd))
      // resultArr.push([start.format(momentFormat), end.format(momentFormat)]);
      resultArr.push({
        startTime: start.format(momentFormat),
        endTime: end.format(momentFormat),
      });

    index++;
  }
  return resultArr;
};

export const uploadFile = async (firebase, file, directory = "", fileId) => {
  if (!firebase || !file || !directory || !fileId) {
    toast.error("called uploadFile() with invalid params");
    return;
  }
  // const toastId = useRef(null);
  let toastId = {};
  const filePath = `${directory}${fileId}`;
  const storageRef = ref(firebase.storage, filePath);
  const uploadTask = uploadBytesResumable(storageRef, file);
  return new Promise((resolve) => {
    toastId.current = toast.info("Uploading: 0%");
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        toast.update(toastId.current, {
          render: `Uploading: ${(
            (100 * snapshot.bytesTransferred) /
            snapshot.totalBytes
          ).toFixed(2)}%`,
          autoClose: false,
          type: "info",
        });
      },
      null,
      () => {
        getDownloadURL(storageRef).then((downloadUrl) => {
          toast.update(toastId.current, {
            render: `Uploading completed: 100.00%`,
            autoClose: 3000,
            type: "success",
          });
          resolve({ downloadUrl, id: fileId });
        });
      }
    );
  });
};
export const uploadFileWithRandomId = async (
  firebase,
  file,
  directory = ""
) => {
  const id = uuidv4();
  const res = await uploadFile(firebase, file, directory, id);
  return res;
};

// checks if 2 events are overlapping (each event has to have start_time and end_time properties)
// if an event has isRecurring property set to true , the function will consider the recurring behaviour
//ex event => {start_time: new Date(), end_time: new Date(), isRecurring: true|false }
export const areTwoEventsOverlapping = (firstEvent, secondEvent) => {
  if (
    !firstEvent?.start_time ||
    !firstEvent?.end_time ||
    !secondEvent?.start_time ||
    !secondEvent?.end_time
  )
    throw new Error("Couldn't find start_time or end_time for an event");

  const firstEventDuration = getTimeDiffInMins(
    firstEvent.start_time,
    firstEvent.end_time
  );
  const secondEventDuration = getTimeDiffInMins(
    secondEvent.start_time,
    secondEvent.end_time
  );

  let firstEventStart, firstEventEnd, secondEventStart, secondEventEnd;

  if (firstEvent.isRecurring && !secondEvent.isRecurring) {
    // check if there is an overlapping possibility (if the recurring event started before the normal event and if they are on the same week day)

    const isCurrentRecurringEvent = moment(
      firstEvent.start_time
    ).isSameOrBefore(secondEvent.start_time);
    if (isCurrentRecurringEvent) {
      firstEventStart = isSameWeekDay(
        firstEvent.start_time,
        secondEvent.start_time
      )
        ? getFullDateFromDateAndTimeInputs(
            secondEvent.start_time,
            moment(firstEvent.start_time).format("HH:mm")
          )
        : isSameWeekDay(firstEvent.start_time, secondEvent.end_time)
        ? getFullDateFromDateAndTimeInputs(
            secondEvent.end_time,
            moment(firstEvent.start_time).format("HH:mm")
          )
        : isSameWeekDay(firstEvent.end_time, secondEvent.start_time)
        ? getFullDateFromDateAndTimeInputs(
            moment(secondEvent.start_time).subtract(1, "day"),
            moment(firstEvent.start_time).format("HH:mm")
          )
        : firstEvent.start_time;
    } else {
      firstEventStart = firstEvent.start_time;
    }

    secondEventStart = secondEvent.start_time;
  } else if (!firstEvent.isRecurring && secondEvent.isRecurring) {
    const isCurrentRecurringEvent = moment(
      secondEvent.start_time
    ).isSameOrBefore(firstEvent.start_time);

    firstEventStart = firstEvent.start_time;

    if (isCurrentRecurringEvent) {
      secondEventStart = isSameWeekDay(
        firstEvent.start_time,
        secondEvent.start_time
      )
        ? getFullDateFromDateAndTimeInputs(
            firstEvent.start_time,
            moment(secondEvent.start_time).format("HH:mm")
          )
        : isSameWeekDay(secondEvent.start_time, firstEvent.end_time)
        ? getFullDateFromDateAndTimeInputs(
            firstEvent.end_time,
            moment(secondEvent.start_time).format("HH:mm")
          )
        : isSameWeekDay(secondEvent.end_time, firstEvent.start_time)
        ? getFullDateFromDateAndTimeInputs(
            moment(firstEvent.start_time).subtract(1, "day"),
            moment(secondEvent.start_time).format("HH:mm")
          )
        : secondEvent.start_time;
    } else {
      secondEventStart = secondEvent.start_time;
    }
  } else if (firstEvent.isRecurring && secondEvent.isRecurring) {
    if (isSameWeekDay(firstEvent.start_time, secondEvent.start_time)) {
      firstEventStart = getFullDateFromDateAndTimeInputs(
        updatedMomentNow(),
        moment(firstEvent.start_time).format("HH:mm")
      );
      secondEventStart = getFullDateFromDateAndTimeInputs(
        updatedMomentNow(),
        moment(secondEvent.start_time).format("HH:mm")
      );
    } else if (isSameWeekDay(firstEvent.start_time, secondEvent.end_time)) {
      firstEventStart = getFullDateFromDateAndTimeInputs(
        updatedMomentNow(),
        moment(firstEvent.start_time).format("HH:mm")
      );
      secondEventStart = getFullDateFromDateAndTimeInputs(
        updatedMomentNow().subtract(1, "day"),
        moment(secondEvent.start_time).format("HH:mm")
      );
    } else if (isSameWeekDay(firstEvent.end_time, secondEvent.start_time)) {
      firstEventStart = getFullDateFromDateAndTimeInputs(
        updatedMomentNow().subtract(1, "day"),
        moment(firstEvent.start_time).format("HH:mm")
      );
      secondEventStart = getFullDateFromDateAndTimeInputs(
        updatedMomentNow(),
        moment(secondEvent.start_time).format("HH:mm")
      );
    } else {
      firstEventStart = firstEvent.start_time;
      secondEventStart = secondEvent.start_time;
    }
  } else {
    // both events aren't recurring
    firstEventStart = firstEvent.start_time;
    secondEventStart = secondEvent.start_time;
  }
  // console.log({
  //   firstEventStart: moment(firstEventStart).toString(),
  //   secondEventStart: moment(secondEventStart).toString(),
  // });
  // updating the end dates
  firstEventEnd = moment(firstEventStart)
    .add(firstEventDuration, "minutes")
    .toDate();
  secondEventEnd = moment(secondEventStart)
    .add(secondEventDuration, "minutes")
    .toDate();

  //

  const areOverlapping =
    moment(firstEventStart).isBetween(
      secondEventStart,
      secondEventEnd,
      "minutes",
      "[)"
    ) ||
    moment(firstEventEnd).isBetween(
      secondEventStart,
      secondEventEnd,
      "minutes",
      "(]"
    ) ||
    moment(secondEventStart).isBetween(
      firstEventStart,
      firstEventEnd,
      "minutes",
      "[)"
    ) ||
    moment(secondEventEnd).isBetween(
      firstEventStart,
      firstEventEnd,
      "minutes",
      "(]"
    );

  if (areOverlapping) {
    // console.log("ACTIVITY OVERLAP DETECTED", {
    //   firstEventStart,
    //   firstEventEnd,
    //   secondEventStart,
    //   secondEventEnd,
    //   firstEvent,
    //   secondEvent,
    // });
  }
  return areOverlapping;
};

export const getFullDateFromDateAndTimeInputs = (
  date,
  time,
  is24HrFormat = true
) => {
  if (!date || !time) throw new Error("Couldn't find date or time");

  const formatIn24Hr = ["HH:mm"];
  // AM or PM (Capital letters)
  const formatIn12Hr = ["hh:mm A"];

  const parsedTime = moment(time, is24HrFormat ? formatIn24Hr : formatIn12Hr);
  const hours = parsedTime.get("hour");
  const minutes = parsedTime.get("minute");

  const fullDate = moment(date)
    .set({
      hour: hours,
      minute: minutes,
      second: 0,
      millisecond: 0,
    })
    .toDate();

  return fullDate;
};

export const isSameWeekDay = (firstDate, secondDate) => {
  if (!firstDate || !secondDate)
    throw new Error("Cant find firstDate or endDate");
  const isSameWeekDay =
    moment(firstDate).get("weekday") === moment(secondDate).get("weekday");
  return isSameWeekDay;
};

// detects if its a recurring event absence
export const isRecurringActivityAbsence = (
  { start, end, id },
  absence,
  options = { excludeTA: false, excludeGCAbsence: true }
) => {
  const { excludeTA, excludeGCAbsence } = options;

  if (!start || !id) throw new Error("Cant find recurring activity info");

  const isExcludedTA = excludeTA && isTeacherAbsence(absence.absenceType);

  const absenceHasEffect =
    isApprovedAbsence(absence?.status) ||
    isPartiallyApprovedAbsence(absence.status);

  if (!absence || !absenceHasEffect || isExcludedTA) return false;

  const { absenceBehaviour } = absence;

  const isPerLesson = isPerLessonAbsence(absenceBehaviour);
  const isGroupClassAbs = isGroupClassAbsence(absence.lessonType);
  const isDuration = isPerDurationAbsence(absenceBehaviour);

  // group class absences doesn't affect the teacher availabilities as it's created by a single student in the roster
  if (isGroupClassAbs && excludeGCAbsence) return false;

  if (isPerLesson) {
    return (
      moment(absence.date).isSame(start, "minute") && absence.lessonId === id
    );
  } else {
    if (!end) throw new Error("Cant find recurring activity info");

    // Duration
    return (
      (moment(start).isBetween(
        absence.startDate,
        absence.endDate,
        "minute",
        "[)"
      ) ||
        moment(end).isBetween(
          absence.startDate,
          absence.endDate,
          "minute",
          "(]"
        )) &&
      absence.affectedPrivateLessonsIds?.includes(id)
    );
  }
};

export const setHoursAndMinsToDate = (date, hours, minutes) => {
  if (
    date === null ||
    hours === null ||
    minutes === null ||
    date === undefined ||
    hours === undefined ||
    minutes === undefined
  ) {
    toast.error("Issue with date, hours or minutes (setHoursAndMinsToDate fn)");
    return null;
  }
  return moment(date)
    .set({
      hours,
      minutes,
      seconds: 0,
      milliseconds: 0,
    })
    .toDate();
};

export const downloadTextFile = (filename = "textDocument", text) => {
  if (!filename || !text) return;

  let element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const getMakeupPeriodInRequestedDate = (
  absenceDate,
  currentSchoolYearInLocation
) => {
  if (!absenceDate || !currentSchoolYearInLocation) return undefined;

  const makeupPeriods = currentSchoolYearInLocation.makeups || {};

  const makeupPeriodsArr = Object.values(makeupPeriods);
  const currentMakeupPeriodDeadline = makeupPeriodsArr.find((makeupPeriod) => {
    return moment(absenceDate).isBetween(
      makeupPeriod.start_date.toDate(),
      makeupPeriod.end_date.toDate(),
      undefined,
      "[]"
    );
  });
  return currentMakeupPeriodDeadline;
};

export const getSchoolYearInDate = (schoolYears, locationId, requestDate) => {
  if (!schoolYears?.length || !locationId || !requestDate) return undefined;

  const currentSchoolYear = schoolYears.find((schoolYear) => {
    // the school year in specific location
    const schoolYearInLocation = schoolYear?.locations?.[locationId];
    const isRequestDateDuringSchoolYear = schoolYearInLocation
      ? moment(requestDate).isBetween(
          schoolYearInLocation.dates.start_date.toDate(),
          schoolYearInLocation.dates.end_date.toDate(),
          "minutes",
          "[]"
        )
      : false;
    return isRequestDateDuringSchoolYear;
  });

  const currentSchoolYearInLessonLocation = getCurrentSchoolYearOfLocation(
    currentSchoolYear,
    locationId
  );

  return currentSchoolYearInLessonLocation;
};

export const getCurrentSchoolYear = (schoolYears, locationId) => {
  if (!schoolYears?.length || !locationId) return undefined;
  const currentSchoolYear = getSchoolYearInDate(
    schoolYears,
    locationId,
    updatedMomentNow().toDate()
  );

  return currentSchoolYear;
};
export const getCurrentSchoolYearOfLocation = (
  currentSchoolYear,
  locationId
) => {
  if (!currentSchoolYear || !locationId) return undefined;

  const { locations, ...currentSchoolYearWithoutLocations } =
    currentSchoolYear || {};

  const currentSchoolYearInLessonLocation = {
    ...currentSchoolYearWithoutLocations,
    ...currentSchoolYear.locations[locationId],
  };

  return currentSchoolYearInLessonLocation;
};
export const getNextSchoolYear = (
  schoolYears,
  locationId,
  currentSchoolYearInLocation
) => {
  if (!schoolYears?.length || !locationId || !currentSchoolYearInLocation)
    return undefined;

  const nextSchoolYear = schoolYears.find((schoolYear) => {
    // the school year in specific location
    const schoolYearInLocation = schoolYear?.locations?.[locationId];
    // checks if the date after 3 months from the current year's end date will be within the next school year
    const isCurrentYearEndDuringSchoolYear = schoolYearInLocation
      ? moment(currentSchoolYearInLocation.dates.end_date.toDate())
          .add(3, "months")
          .isBetween(
            schoolYearInLocation.dates.start_date.toDate(),
            schoolYearInLocation.dates.end_date.toDate()
          )
      : false;
    return isCurrentYearEndDuringSchoolYear;
  });

  const { locations, ...nextSchoolYearWithoutLocations } = nextSchoolYear || {};

  const nextSchoolYearInLessonLocation = nextSchoolYear
    ? {
        ...nextSchoolYearWithoutLocations,
        ...nextSchoolYear.locations[locationId],
      }
    : undefined;

  return nextSchoolYearInLessonLocation;
};
export const getPrevSchoolYear = (
  schoolYears,
  locationId,
  currentSchoolYearInLocation
) => {
  if (!schoolYears?.length || !locationId || !currentSchoolYearInLocation)
    return undefined;

  const prevSchoolYear = schoolYears.find((schoolYear) => {
    // the school year in specific location
    const schoolYearInLocation = schoolYear?.locations?.[locationId];
    // checks if the date after 3 months from the current year's end date will be within the next school year
    const isCurrentYearStartDuringSchoolYear = schoolYearInLocation
      ? moment(currentSchoolYearInLocation.dates.start_date.toDate())
          .subtract(3, "months")
          .isBetween(
            schoolYearInLocation.dates.start_date.toDate(),
            schoolYearInLocation.dates.end_date.toDate(),
            "minutes",
            "[]"
          )
      : false;
    return isCurrentYearStartDuringSchoolYear;
  });

  const { locations, ...prevSchoolYearWithoutLocations } = prevSchoolYear || {};

  const prevSchoolYearInLessonLocation = prevSchoolYear
    ? {
        ...prevSchoolYearWithoutLocations,
        ...prevSchoolYear.locations[locationId],
      }
    : undefined;

  return prevSchoolYearInLessonLocation;
};
export const getCombinedSchoolYears = (schoolYears, locationId) => {
  if (!schoolYears?.length || !locationId) return [];

  const currentSchoolYear = getSchoolYearInDate(
    schoolYears,
    locationId,
    updatedMomentNow().toDate()
  );

  const prevSchoolYear = getPrevSchoolYear(
    schoolYears,
    locationId,
    currentSchoolYear
  );
  const nextSchoolYear = getNextSchoolYear(
    schoolYears,
    locationId,
    currentSchoolYear
  );

  return { prevSchoolYear, currentSchoolYear, nextSchoolYear };
};
export const getCombinedSchoolBreaksFromSchoolYears = (
  schoolYearsInLocation,
  withSchoolYearTitle = false
) => {
  if (!schoolYearsInLocation?.length) return [];

  const combinedBreaks = schoolYearsInLocation
    .map(({ breaks, title }) => {
      const updatedBreaks = Object.values(breaks || {}).map((schoolBreak) => ({
        ...schoolBreak,
        ...(withSchoolYearTitle && { schoolYearTitle: title }),
      }));
      return updatedBreaks || {};
    })
    .flat();

  return combinedBreaks;
};
export const getCombinedSchoolBreaks = (
  schoolYears,
  locationId,
  withSchoolYearTitle = false
) => {
  if (!schoolYears?.length || !locationId) return [];

  const { currentSchoolYear, nextSchoolYear, prevSchoolYear } =
    getCombinedSchoolYears(schoolYears, locationId);

  const combinedSchoolYearsArr = [
    prevSchoolYear,
    currentSchoolYear,
    nextSchoolYear,
  ].filter((el) => el);

  const combinedBreaks = getCombinedSchoolBreaksFromSchoolYears(
    combinedSchoolYearsArr,
    withSchoolYearTitle
  );

  return combinedBreaks;
};
// daily deadline is the time where any absence after it for the next day will be considered SDC , and any absence before it will be considered REG
export const isSameDayOrPassedDeadline = (
  requestDate,
  targetDate,
  dailyDeadline = "17:00"
) => {
  if (!requestDate || !targetDate)
    throw Error("Can't find requestDate or targetDate");

  const MINS_IN_DAY = 1440;
  const timeDiff = getTimeDiffInMins(requestDate, targetDate);
  const isWithin24Hrs = timeDiff < MINS_IN_DAY;
  if (isWithin24Hrs) {
    return true;
  } else {
    return false;
  }
  // const isTargetDateInSameDayAsReqDate = moment(requestDate).isSame(
  //   targetDate,
  //   "date"
  // );
  // const isTargetDateInNextDayOfReqDate = moment(requestDate)
  //   .add(1, "day")
  //   .isSame(targetDate, "date");

  // if (isTargetDateInSameDayAsReqDate) {
  //   return true;
  // } else if (isTargetDateInNextDayOfReqDate) {
  //   const splittedDeadlineTime = dailyDeadline.split(":");
  //   const [deadlineHr, deadlineMin] = splittedDeadlineTime;

  //   const deadlineOnRequestDate = moment(requestDate)
  //     .set({
  //       hours: parseInt(deadlineHr),
  //       minutes: parseInt(deadlineMin),
  //       seconds: 0,
  //       milliseconds: 0,
  //     })
  //     .toDate();

  //   const isPassedDailyDeadline = moment(requestDate).isAfter(
  //     deadlineOnRequestDate,
  //     "minute"
  //   );
  //   if (isPassedDailyDeadline) {
  //     // the request is after the deadline
  //     return true;
  //   } else {
  //     return false;
  //   }
  // } else {
  //   // target date is after tomorrow (not SDC)
  //   return false;
  // }
};
// check if the student reached the max makeup number of lsns for a single private lesson
export const checkIfReachedMaxMakeUps = (
  absences, // absences for a single lesson
  schoolYearInLocation,
  studentMakeups
) => {
  if (!schoolYearInLocation) throw new Error("Couldn't find  school year");

  const isDuringLearningYear = isNowDuringLearningYear(schoolYearInLocation);
  if (!isDuringLearningYear) {
    return { reachedMaxMakeups: false };
  }

  const lessonAbsences = absences || [];

  // only get absences that will count in calculating the maximum no of makeups (REG,SDC,EA)
  const filteredAbsences = lessonAbsences.filter((absence) =>
    makeupLimitAbsenceTypes.includes(parseInt(absence.absenceType))
  );

  // only getting makeups for the filtered absences and were made in the same learning year
  const filteredAbsencesIds = filteredAbsences.map((absence) => absence.id);
  const filteredMakeupAbsencesIds = studentMakeups
    .filter(
      (lsn) =>
        filteredAbsencesIds.includes(lsn.forAbsenceId) &&
        moment(lsn.startDate).isBetween(
          schoolYearInLocation.dates.learning_year_start.toDate(),
          schoolYearInLocation.dates.learning_year_end.toDate(),
          undefined,
          "[]"
        )
    )
    .map((lsn) => lsn.forAbsenceId);
  // getting the absenceIds for all makeups then filter out duplicate absenceIds (bec there might be multiple makeup splits for a single absence)
  const uniqueMakeupsAbsencesIds = [...new Set(filteredMakeupAbsencesIds)];
  if (uniqueMakeupsAbsencesIds.length < 4) {
    return { reachedMaxMakeups: false };
  } else {
    return { reachedMaxMakeups: true };
  }
};
export const isNowDuringLearningYear = (schoolYearInLocation) => {
  const currentSchoolYearDates = schoolYearInLocation.dates;
  const { learning_year_start, learning_year_end } = currentSchoolYearDates;

  return updatedMomentNow().isBetween(
    learning_year_start.toDate(),
    learning_year_end.toDate(),
    undefined,
    "[]"
  );
};
export const isDateDuringSummerSession = (schoolYearInLocation, date) => {
  if (!schoolYearInLocation || !date) return false;

  const currentSchoolYearDates = schoolYearInLocation.dates;
  const { summer_session_start, summer_session_end } =
    currentSchoolYearDates || {};

  const summerSessionStart = summer_session_start
    ? summer_session_start.toDate()
    : null;
  const summerSessionEnd = summer_session_end
    ? summer_session_end.toDate()
    : null;

  return (
    summerSessionStart &&
    summerSessionEnd &&
    moment(date).isBetween(
      summerSessionStart,
      summerSessionEnd,
      "minutes",
      "[]"
    )
  );
};

// const dateRanges = [{ start_time: new Date(), end_time: new Date() }];
export const mergeOverlappingDateRanges = (dateRanges) => {
  if (!dateRanges?.length) return [];

  const sorted = dateRanges
    .sort(
      (a, b) =>
        moment(a.start_time).set({ second: 0, millisecond: 0 }) -
        moment(b.start_time).set({ second: 0, millisecond: 0 })
    )
    .map((slot) => ({
      ...slot,
      start_time: moment(slot.start_time)
        .set({ second: 0, millisecond: 0 })
        .toDate(),
      end_time: moment(slot.end_time)
        .set({ second: 0, millisecond: 0 })
        .toDate(),
    }));

  const ret = sorted.reduce((acc, curr) => {
    // Skip the first range
    if (acc.length === 0) {
      return [curr];
    }

    const prev = acc.pop();

    if (curr.end_time <= prev.end_time) {
      // Current range is completely inside previous
      return [...acc, prev];
    }

    // Merges overlapping (<) and contiguous (==) ranges
    if (curr.start_time <= prev.end_time) {
      // Current range overlaps previous
      return [...acc, { start_time: prev.start_time, end_time: curr.end_time }];
    }

    // Ranges do not overlap
    return [...acc, prev, curr];
  }, []);
  return ret;
};

// gets the week number of a recurring event occurance
export const getRecurringOccuranceWeekNumber = (startDate, occuranceDate) => {
  if (!startDate || !occuranceDate) return;

  let week = 0;
  let isFound = false;
  while (week < 3000) {
    const currentOccurance = moment(startDate).add(week, "weeks").toDate();

    const isMatching = moment(occuranceDate).isSame(
      currentOccurance,
      "minutes"
    );
    if (isMatching) {
      isFound = true;
      break;
    }
    week++;
  }

  return isFound ? week + 1 : null;
};

// occuranceDate is a date where the recurring event happens (similar to startDate but in the future)
export const getRecurringEventModification = (
  startDate,
  occuranceDate,
  modifications,
  currentTimelineObjId
) => {
  if (!startDate || !occuranceDate) return;

  const occuranceWeekNumber = getRecurringOccuranceWeekNumber(
    startDate,
    occuranceDate
  );
  const foundModification = modifications?.find(
    ({ weekNumber, timelineObjId }) =>
      parseInt(occuranceWeekNumber) === parseInt(weekNumber) &&
      timelineObjId === currentTimelineObjId
  );

  return foundModification;
};

export const isRequestDateWithinSchoolYear = (
  requestDate,
  currentSchoolYearInLocation
) => {
  if (!currentSchoolYearInLocation || !requestDate) return false;

  const currentSchoolYearStartDate =
    currentSchoolYearInLocation?.dates?.start_date?.toDate &&
    currentSchoolYearInLocation?.dates?.start_date?.toDate();
  const currentSchoolYearEndDate =
    currentSchoolYearInLocation?.dates?.end_date?.toDate &&
    currentSchoolYearInLocation?.dates?.end_date?.toDate();

  const startDateFilter = currentSchoolYearStartDate;
  const endDateFilter = currentSchoolYearEndDate;

  // const makeupStartDates = Object.values(
  //   currentSchoolYearInLocation?.makeups || []
  // ).map(({ start_date }) => start_date && start_date.toDate());
  // const startDateOfFirstMakeUpPeriod = new Date(Math.min(...makeupStartDates));

  // const makeupEndDates = Object.values(
  //   currentSchoolYearInLocation?.makeups || []
  // ).map(({ end_date }) => end_date && end_date.toDate());
  // const endDateOfLastMakeUpPeriod = new Date(Math.max(...makeupEndDates));

  // // the end date for the displayed absences filter is the date of the last makeup period end date or the school year end date (whatever is later)
  // const endDateFilter = moment(endDateOfLastMakeUpPeriod).isSameOrAfter(
  //   currentSchoolYearEndDate
  // )
  //   ? endDateOfLastMakeUpPeriod
  //   : currentSchoolYearEndDate;

  // // the start date for the displayed absences filter is the date of the first makeup period start date or the school year start date (whatever is first)
  // const startDateFilter = moment(startDateOfFirstMakeUpPeriod).isSameOrBefore(
  //   currentSchoolYearStartDate
  // )
  //   ? startDateOfFirstMakeUpPeriod
  //   : currentSchoolYearStartDate;

  const isRequestDateDuringCurrentSchoolYear = moment(requestDate).isBetween(
    startDateFilter,
    endDateFilter,
    undefined,
    "[]"
  );

  return isRequestDateDuringCurrentSchoolYear;
};

// startTime and endTime must be in 'HH:mm' format
export const checkStartTimeIsBeforeEndTime = (startTime, endTime) => {
  if (!startTime || !endTime) return;

  const momStartTime = moment(startTime, "HH:mm");
  const momEndTime = moment(endTime, "HH:mm");
  return moment(momStartTime).isBefore(momEndTime);
};

// input the month and day number , outputs startDate and endDate for 1 year duration
export const getFilterStartAndEndDate = (startDateMonth, startDateDay) => {
  if (!startDateMonth || !startDateDay) return;

  // gets the current year's date for the start filter (1 Aug)
  const currentYearStartFilter = updatedMomentNow()
    .set({
      month: parseInt(startDateMonth),
      date: parseInt(startDateDay),
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    })
    .toDate();
  // if today's date is before the date of the start filter for the current year, subtract a year from the start date filter, else use the current year's date for start filter
  const startDateFilter = updatedMomentNow().isBefore(currentYearStartFilter)
    ? moment(currentYearStartFilter).subtract(1, "year").toDate()
    : currentYearStartFilter;
  const endDateFilter = moment(startDateFilter)
    .add(1, "year")
    .subtract(1, "day")
    .toDate();

  return { startDateFilter, endDateFilter };
};

export const mapDurationOrAllDayAbsenceToActivity = (absence) => {
  const isPerLesson = isPerLessonAbsence(absence.absenceBehaviour);
  const isPerDuration = isPerDurationAbsence(absence.absenceBehaviour);

  const { date, startDate, endDate } = absence;

  if (isPerDuration) {
    return {
      start_time: startDate,
      end_time: endDate,
      isRecurring: false,
    };
  }

  console.log("NOT ALL DAY OR DURATION ABSENCE !!", { absence });
};

// trims a time slot
// ex: timeSLot= {start_time:new Date(), end_time: new Date()}
export const trimTimeSlot = (timeSlot, start, end) => {
  if (!timeSlot) return;

  const slotStart = moment(start).isSameOrBefore(timeSlot.start_time)
    ? timeSlot.start_time
    : start;
  const slotEnd = moment(end).isSameOrAfter(timeSlot.end_time)
    ? timeSlot.end_time
    : end;

  return {
    ...timeSlot,
    start_time: slotStart,
    end_time: slotEnd,
  };
};

// input , busySlots before any modifications (mapping activities + TAs activities into slots)
// outputs busySlots within the teacher day
export const prepareBusySlots = (
  busySlots = [],
  teacherDayStart,
  teacherDayEnd
) => {
  if (!busySlots?.length) return [];

  // only gets busySlots that overlap with the teacherDay (otherwise the busyslot shouldnt be considered)
  // isRecurring is false as at this point , busySlots and teacherDay should be on teh same day
  const filteredBusySlots = busySlots.filter((busySlot) =>
    areTwoEventsOverlapping(
      {
        start_time: busySlot.start_time,
        end_time: busySlot.end_time,
        isRecurring: false,
      },
      {
        start_time: teacherDayStart,
        end_time: teacherDayEnd,
        isRecurring: false,
      }
    )
  );
  // trim busy slots so if there are busy slots that start or end outside of the teacherDay start and end intervals, it will be trimmed(shortened)
  const trimmedBusySlots = filteredBusySlots.map((busySlot) =>
    trimTimeSlot(busySlot, teacherDayStart, teacherDayEnd)
  );

  //combines overlapping events (In case we have 2 single events on same weekday on diff weeks that has overlapping time)
  const combinedBusySlots = mergeOverlappingDateRanges(trimmedBusySlots);
  return combinedBusySlots;
};
export const convertDurationOrAllDayAbsenceToBusySlot = (
  absence,
  workDayStart,
  workDayEnd
) => {
  const isPerDuration = isPerDurationAbsence(absence.absenceBehaviour);

  const { date, startDate, endDate } = absence;
  if (isPerDuration) {
    const start = moment(startDate).isBefore(workDayStart)
      ? workDayStart
      : startDate;
    const end = moment(endDate).isAfter(workDayEnd) ? workDayEnd : endDate;
    return {
      start_time: start,
      end_time: end,
    };
  } else {
    console.log("cant convert per lesson abs to busy slot");
    return;
  }
};

// inputs the recurring occurance date + the activity
// outputs whether the activity is canceled on that date or not (based on canceledDates and canceledAt props)
export const checkIfCanceledRecurringActivity = ({
  occuranceDate = "",
  canceledAt = "",
  canceledDates = [],
  canceledDateRanges = [],
  granularity,
}) => {
  if (!occuranceDate) {
    toast.error(
      "checkIfCanceledRecurringActivity was called with invalid params"
    );
    return;
  }

  let isFutureCanceled = false;
  let isOnCanceledDate = false;
  let isWithinCanceledRange = false;
  if (canceledAt) {
    isFutureCanceled = checkIfCanceledFutureEvents({
      occuranceDate,
      canceledAt,
      canceledDateRanges,
    });
  }
  if (canceledDates?.length) {
    isOnCanceledDate = checkIfOneTimeCanceled({ occuranceDate, canceledDates });
  }
  if (canceledDateRanges?.length) {
    isWithinCanceledRange = checkIfWithinCanceledDateRanges({
      occuranceDate,
      canceledDateRanges,
      granularity,
    });
  }

  const isCanceled =
    isFutureCanceled || isOnCanceledDate || isWithinCanceledRange;

  return isCanceled;
};

export const checkIfCanceledFutureEvents = ({
  occuranceDate,
  canceledAt,
  canceledDateRanges = [],
}) => {
  if (!occuranceDate) return false;

  const isFutureCanceled = canceledAt
    ? moment(occuranceDate).isSameOrAfter(canceledAt, "date")
    : false;
  const isDuringCanceledDateRange = canceledDateRanges?.some(
    ({ start, end }) => {
      if (!start || end) return false; // must not have an end date so its canceled forever
      return moment(occuranceDate).isSameOrAfter(start, "date");
    }
  );

  const isCanceled = isFutureCanceled || isDuringCanceledDateRange;

  return isCanceled;
};
export const checkIfOneTimeCanceled = ({ occuranceDate, canceledDates }) => {
  if (!canceledDates?.length || !occuranceDate) return false;

  const isCanceled = canceledDates?.some((date) =>
    moment(occuranceDate).isSame(date, "date")
  );

  return isCanceled;
};
export const checkIfWithinCanceledDateRanges = ({
  occuranceDate,
  canceledDateRanges,
  granularity = "[]",
}) => {
  if (!canceledDateRanges?.length || !occuranceDate) return false;

  const isCanceled = canceledDateRanges?.some(({ start, end }) => {
    if (!start) return false;

    return moment(occuranceDate).isBetween(
      start,
      end || updatedMomentNow().add(100, "years"),
      "minutes",
      granularity
    );
  });

  return isCanceled;
};

export const formatAttendanceList = (attendance, selectedLesson) => {
  console.log({ selectedLesson });
  if (!selectedLesson?.lessonDates?.length) {
    return [];
  }

  let attendanceArray = [];

  if (selectedLesson.isPackageLsn) {
    attendanceArray.push(
      ...selectedLesson.lessonDates.map(({ startDate }) =>
        moment(startDate.toDate())
      )
    );
  } else {
    for (const teacherPeriod of selectedLesson.lessonDates) {
      const startDate = moment(teacherPeriod.startDate.toDate());
      const endDate = moment(teacherPeriod.lastDate.toDate());
      let currentDate = moment(startDate);
      while (currentDate.isBefore(endDate)) {
        attendanceArray.push(currentDate);
        currentDate = moment(currentDate).add(1, "week");
      }
    }
  }
  attendanceArray = attendanceArray.slice(0, 45);
  const arr = attendanceArray.map((item, index) => {
    const lessonDate = moment(item);
    const weekNumber = index + 1;
    const attendanceObject = attendance?.[weekNumber];
    return {
      lessonId: selectedLesson.lessonId,
      lessonDate,
      locationId: attendanceObject?.locationId ?? "",
      attendanceDate: attendanceObject
        ? moment(attendanceObject.date.toDate()).format("MM-DD-YYYY hh:mmA")
        : "",
      isAttended: !!attendanceObject,
      weekNumber,
      id: attendanceObject ? attendanceObject.id : undefined,
    };
  });

  return arr;
};
export const getRecurringEventTimelineObj = ({
  timeline = [],
  requestDate,
  startDateAccessor = "",
  lastDateAccessor = "",
  granularity = "date",
  withApproximation = false, // if true, it gets the nearest timeline if the requestDate has no timeline
  // only use withApproximation to display data , "DONT ENABLE IT IN AVAILABILITY CALCULATIONS"
}) => {
  if (
    !requestDate ||
    !timeline?.length ||
    !startDateAccessor ||
    !lastDateAccessor
  ) {
    toast.error("error with getRecurringEventTimelineObj function");
    return;
  }

  let currentTimelineObj = timeline.find((timelineObj) => {
    const isCurrentTimelineObj = checkIfDateInTimelineObj({
      timelineObj,
      startDateAccessor,
      lastDateAccessor,
      requestDate,
      granularity,
    });
    return isCurrentTimelineObj;
  });

  // if there's no currentTimelineObj and we enabled withApproximation, then we check if the lesson has 1 timeline or the requestDate is before the first timeline and we return that timeline ,
  // else we check if the requestDate is between 2 timelines and return the first timeline
  if (!currentTimelineObj && withApproximation) {
    if (
      timeline.length === 1 ||
      moment(requestDate).isSameOrBefore(timeline[0]?.startDate, "date")
    ) {
      currentTimelineObj = timeline[0];
    } else if (
      moment(requestDate).isSameOrAfter(
        timeline[timeline.length - 1]?.startDate,
        "date"
      )
    ) {
      currentTimelineObj = timeline[timeline.length - 1];
    } else {
      // gets the first timeline if the requestDate lies between the end of a timeline and the begining of another
      currentTimelineObj = timeline.find((timelineObj, i, arr) => {
        const firstTimeline = arr[i];
        const secondTimeline = arr[i + 1];
        if (
          !firstTimeline ||
          !firstTimeline[lastDateAccessor] ||
          !secondTimeline ||
          !secondTimeline[startDateAccessor]
        )
          return false;

        const isValid = moment(requestDate).isBetween(
          firstTimeline[lastDateAccessor],
          secondTimeline[startDateAccessor],
          "minute",
          "[]"
        );

        return isValid;
      });
    }
  }
  return currentTimelineObj;
};

export const checkIfDateInTimelineObj = ({
  timelineObj,
  startDateAccessor,
  lastDateAccessor,
  requestDate,
  granularity = "date",
}) => {
  if (!timelineObj || !startDateAccessor || !lastDateAccessor || !requestDate)
    return false;
  const start = timelineObj[startDateAccessor];
  // the timelineObj end date is  the last date of the timeline if the timeline has an end
  // if the timelineObj doesn't have an end, we set the end date as 50 years from now
  const end =
    timelineObj[lastDateAccessor] ||
    updatedMomentNow().add(50, "years").toDate();

  if (!start) {
    toast.error("couldnt find (start) in checkIfDateInTimelineObj fn");
    return false;
  }

  const isCurrentTimelineObj = moment(requestDate).isBetween(
    start,
    end,
    granularity,
    "[]"
  );
  return isCurrentTimelineObj;
};

export const getPrivateLessonUniqueTeachersIds = (lesson) => {
  if (!lesson) return [];

  const teachers = lesson.timeline?.map(({ teacherId }) => teacherId);

  return [...new Set(teachers)];
};

export const getPrivateLessonUniqueLocationsIds = (lesson) => {
  if (!lesson) return [];
  const isPackageLsn = isPackagePrivateLesson(lesson.type);

  const locationsIds = isPackageLsn
    ? getCombinedPackageSetItems(lesson.packageSets)?.map(
        ({ locationId }) => locationId
      )
    : lesson.timeline?.map(({ locationId }) => locationId);

  return [...new Set(locationsIds)];
};

export const getLessonWithCurrentTimeline = ({
  lesson,
  requestDate = updatedMomentNow().toDate(),
  granularity = "date", // assumes that we can't have 2 timelines that has a common date
  withApproximation = false,
}) => {
  if (!lesson) {
    toast.error("ERR with (getLessonWithCurrentTimeline) fn");
    return;
  }
  if (!requestDate) {
    toast.error("ERR with (getLessonWithCurrentTimeline) fn");
    return lesson;
  }

  const currentTimeline = getRecurringEventTimelineObj({
    timeline: lesson?.timeline,
    startDateAccessor: "startDate",
    lastDateAccessor: "lastDate",
    requestDate: requestDate,
    granularity,
    withApproximation,
  });

  const lessonWithCurrentTimeline = {
    ...lesson,
    currentTimeline,
  };

  return lessonWithCurrentTimeline;
};

// gets current/future timeline on the given weekday
export const getFutureTimelineOnWeekday = ({
  timeline = [],
  requestDate,
  startDateAccessor = "",
  endDateAccessor = "", // endDateAccessor in case withDuration= false
  lastDateAccessor = "",
  granularity = "date",
  withDuration, // T/F , if true then there's a duration property in the timeline
  durationAccessor = "duration",
}) => {
  if (
    !requestDate ||
    !timeline?.length ||
    !startDateAccessor ||
    !lastDateAccessor
  ) {
    toast.error("error with getTimelineOnSameFutureWeekDay function");
    return;
  }
  const weekday = moment(requestDate).day();

  const currentOrFutureTimelineOnWeekday = timeline.find((timelineObj) => {
    const lessonStart = timelineObj[startDateAccessor];
    // assuming that the timeline will have either end_time or
    const lessonEnd = withDuration
      ? moment(lessonStart).add(parseInt(timeline[durationAccessor]), "minutes")
      : timelineObj[endDateAccessor];

    const timelineLastDate = timelineObj[lastDateAccessor];

    //past timeline (ended) wont be considered
    const isPastTimeline =
      timelineLastDate &&
      moment(timelineLastDate).isBefore(requestDate, granularity);
    if (isPastTimeline) return false;

    return (
      moment(lessonStart).day() === weekday ||
      moment(lessonEnd).day() === weekday
    );
  });

  return currentOrFutureTimelineOnWeekday;
};

export const isActivityOverlappingWithSingleTimeEvent = ({
  eventStartDate,
  eventEndDate,
  eventId, // to prevent checking against the same event in case of editing
  teacherId,
  activity,
  absences, // absences can be either only the teacher's Absences or all absences (bec we check with the activity id anyways)
}) => {
  if (
    !eventStartDate ||
    !eventEndDate ||
    !teacherId ||
    !activity ||
    !absences
  ) {
    toast.error("Missing params (isActivityOverlappingWithSingleTimeEvent)");
    return false;
  }

  const isRecurringActivity = isRecurringEvent(
    activity.type,
    activity.privateLessonType
  );
  const isPackageActivity = isPackagePrivateLesson(activity.privateLessonType);
  // gets timeline obj in the makeup lesson date
  const timelineObj =
    isRecurringActivity &&
    getRecurringEventTimelineObj({
      requestDate: eventStartDate,
      timeline: activity.timeline,
      startDateAccessor: "start_time",
      lastDateAccessor: "lastDate",
    });
  const isSameTeacherOnTimeline = timelineObj?.userId === teacherId;

  // if recurring activity and there is no timeline or the teacher of the timeline is different then its not overlapping
  if (isRecurringActivity && !isSameTeacherOnTimeline) {
    return false;
  }

  const activityId = activity.docId || activity.id;

  const requestWeekDay = moment(eventStartDate).weekday();
  const sameWeekday =
    moment(timelineObj.start_time).weekday() === requestWeekDay ||
    moment(timelineObj.end_time).weekday() === requestWeekDay;

  let isAbsence = false;
  let isCanceled = false;
  if (isRecurringActivity && sameWeekday) {
    const recurringDuration = getTimeDiffInMins(
      timelineObj.start_time,
      timelineObj.end_time
    );
    const recurringActivityStart = isSameWeekDay(
      timelineObj.start_time,
      eventStartDate
    )
      ? getFullDateFromDateAndTimeInputs(
          eventStartDate,
          moment(timelineObj.start_time).format("HH:mm")
        )
      : isSameWeekDay(timelineObj.start_time, eventEndDate)
      ? getFullDateFromDateAndTimeInputs(
          eventEndDate,
          moment(timelineObj.start_time).format("HH:mm")
        )
      : isSameWeekDay(timelineObj.end_time, eventStartDate)
      ? getFullDateFromDateAndTimeInputs(
          moment(eventStartDate).subtract(1, "day"),
          moment(timelineObj.start_time).format("HH:mm")
        )
      : timelineObj.start_time;

    const recurringActivityEnd = moment(recurringActivityStart).add(
      recurringDuration,
      "minutes"
    );
    // const recurringActivityStart = moment(eventStartDate)
    //   .set({
    //     hour: moment(timelineObj?.start_time).hour(),
    //     minute: moment(timelineObj?.start_time).minute(),
    //     seconds: 0,
    //     milliseconds: 0,
    //   })
    //   .toDate();
    // const recurringActivityEnd = moment(eventStartDate)
    //   .set({
    //     hour: moment(timelineObj?.end_time).hour(),
    //     minute: moment(timelineObj?.end_time).minute(),
    //     seconds: 0,
    //     milliseconds: 0,
    //   })
    //   .toDate();

    //check if absence
    isAbsence = absences?.some((absence) => {
      return isRecurringActivityAbsence(
        {
          start: recurringActivityStart,
          end: recurringActivityEnd,
          id: activityId,
        },
        absence,
        { excludeTA: true, excludeGCAbsence: true }
      );
    });

    //check if canceled activity
    isCanceled = checkIfCanceledRecurringActivity({
      occuranceDate: recurringActivityStart,
      canceledAt: activity.canceledAt,
      canceledDates: activity.canceledDates,
      canceledDateRanges: activity.canceledRanges,
    });
  } else if (isPackageActivity) {
    isAbsence = absences?.some((absence) => {
      return isRecurringActivityAbsence(
        {
          start: activity.start_time,
          end: activity.end_time,
          id: activityId,
        },
        absence,
        { excludeTA: true, excludeGCAbsence: true }
      );
    });
  }

  const activityStart = timelineObj?.start_time || activity.start_time;
  const activityEnd = timelineObj?.end_time || activity.end_time;

  const isSameEvent = activityId && eventId && activityId === eventId;
  if (
    // don't check against the same lesson (in case of editing)
    !isSameEvent &&
    !isAbsence &&
    //check if the activity is canceled on that date
    !isCanceled &&
    areTwoEventsOverlapping(
      {
        start_time: eventStartDate,
        end_time: eventEndDate,
        isRecurring: false,
      },
      {
        start_time: activityStart,
        end_time: activityEnd,
        isRecurring: isRecurringEvent(
          activity.type,
          activity.privateLessonType
        ),
      }
    )
  ) {
    console.log("DETECTED OVERLAP WITH: ", {
      activity: {
        ...activity,
        start_time: moment(activity.start_time).toString(),
        end_time: moment(activity.end_time).toString(),
      },
      eventInfo: {
        eventStartDate: moment(eventStartDate).toString(),
        eventEndDate: moment(eventEndDate).toString(),
        eventId,
      },
      absences,
    });
    return true;
  }
};

//=====

export const isActivityOverlappingWithRecurringEvent = ({
  eventStartDate,
  eventEndDate,
  eventId,
  teacherId,
  activity,
}) => {
  if (!eventStartDate || !eventEndDate || !teacherId || !activity) {
    toast.error("Missing params (isActivityOverlappingWithRecurringEvent)");
    return false;
  }
  const isRecurring = isRecurringEvent(
    activity.type,
    activity.privateLessonType
  );

  // timelineObj in the same weekday currently or in the future
  let timelineObj;
  if (isRecurring) {
    timelineObj =
      getFutureTimelineOnWeekday({
        requestDate: eventStartDate,
        timeline: activity.timeline,
        startDateAccessor: "start_time",
        endDateAccessor: "end_time",
        lastDateAccessor: "lastDate",
      }) ||
      getFutureTimelineOnWeekday({
        requestDate: eventEndDate,
        timeline: activity.timeline,
        startDateAccessor: "start_time",
        endDateAccessor: "end_time",
        lastDateAccessor: "lastDate",
      });
  }
  const isSameTeacherOnTimeline = timelineObj?.userId === teacherId;

  // if recurring activity and there is no timeline or the teacher of the timeline is different then its not overlapping
  if (isRecurring && !isSameTeacherOnTimeline) {
    return false;
  }

  const activityStart = timelineObj?.start_time || activity.start_time;
  const activityEnd = timelineObj?.end_time || activity.end_time;

  // checks if the event is canceled (for all future events + canceled date must be in past)
  // we dont check for absences because its irrelevant in recurring events
  const isFutureCanceledActivity = checkIfCanceledFutureEvents({
    occuranceDate: eventStartDate,
    canceledAt: activity.canceledAt,
    canceledDateRanges: activity.canceledRanges,
  });

  const activityId = activity.docId || activity.id;
  const isSameEvent = activityId && eventId && activityId === eventId;

  if (
    // don't check against the same lesson (in case of editing)
    !isSameEvent &&
    !isFutureCanceledActivity &&
    areTwoEventsOverlapping(
      {
        start_time: eventStartDate,
        end_time: eventEndDate,
        isRecurring: true,
      },
      {
        start_time: activityStart,
        end_time: activityEnd,
        isRecurring: isRecurring,
      }
    )
  ) {
    return true;
  }
};

export const getStartAndEndDatesFromTimeInputs = (date, startTime, endTime) => {
  if (!startTime || !endTime) return;

  const isEndTimeNextDay = moment(startTime, "HH:mm").isAfter(
    moment(endTime, "HH:mm"),
    "minutes"
  );

  const startDate = getFullDateFromDateAndTimeInputs(date, startTime);
  const endDate = isEndTimeNextDay
    ? getFullDateFromDateAndTimeInputs(moment(date).add(1, "day"), endTime)
    : getFullDateFromDateAndTimeInputs(date, endTime);

  return { startDate, endDate };
};
export const getMakeupOpeningDates = (date, startTime, endTime) => {
  if (!startTime || !endTime || !date) return;

  const isEndTimeNextDay = moment(startTime, "HH:mm").isAfter(
    moment(endTime, "HH:mm"),
    "minutes"
  );

  const startDate = getFullDateFromDateAndTimeInputs(date, startTime);
  const endDate = isEndTimeNextDay
    ? getFullDateFromDateAndTimeInputs(moment(date).add(1, "day"), endTime)
    : getFullDateFromDateAndTimeInputs(date, endTime);

  return { startDate, endDate };
};
// checks if the teacherday is in the request date (either by the day start or end)
export const isTeacherDayInRequestWeekday = (requestWeekday, teacherDay) => {
  if ((!requestWeekday && requestWeekday !== 0) || !teacherDay) return false;
  requestWeekday = parseInt(requestWeekday);

  const { startDate, endDate } = teacherDay;

  const startDateWeekday = moment(startDate).day();
  const endDateWeekday = moment(endDate).day();

  const isSameWeekday =
    requestWeekday === startDateWeekday || requestWeekday === endDateWeekday;

  return isSameWeekday;
};
export const isMakeupOpeningInRequestDate = (requestDate, makeupOpening) => {
  if (!requestDate || !makeupOpening) return false;

  const { startDate, endDate } = makeupOpening;

  const isSameDate =
    moment(requestDate).isSame(startDate, "date") ||
    moment(requestDate).isSame(endDate, "date");
  return isSameDate;
};

// prepare the teacherDays based on the request date by filtering out the days that arent matching the request date + adds 2 extra props ( newStartDate, newEndDate ) that represents the teacherDay on the requestDate
export const prepareTeacherDays = (requestDate, teacherDays = []) => {
  if (!requestDate || !teacherDays?.length) return [];

  const requestDateWeekday = moment(requestDate).day();

  const filteredTeacherDays = teacherDays.filter((day) =>
    isTeacherDayInRequestWeekday(requestDateWeekday, day)
  );

  const teacherDaysInRequestDate = filteredTeacherDays.map((day) => {
    const { startDate, endDate } = day;

    const { newStartDate, newEndDate } = getNewDatesOnRequestDateByWeekday(
      requestDate,
      startDate,
      endDate
    );

    return {
      ...day,
      newStartDate,
      newEndDate,
    };
  });

  return teacherDaysInRequestDate;
};

export const prepareMakeupOpenings = (requestDate, makeupOpenings = []) => {
  if (!requestDate || !makeupOpenings?.length) return [];

  const filteredMakeupOpenings = makeupOpenings.filter((opening) =>
    isMakeupOpeningInRequestDate(requestDate, opening)
  );
  const makeupOpeningsInRequestDate = filteredMakeupOpenings.map((opening) => {
    const { startDate, endDate } = opening;
    const { newStartDate, newEndDate } = getNewDatesOnRequestDateByWeekday(
      requestDate,
      startDate,
      endDate
    );

    return {
      ...opening,
      newStartDate,
      newEndDate,
    };
  });

  return makeupOpeningsInRequestDate;
};

// inputs the requestDate , a startDate and an endDate
// outputs {newStartDate, newEndDate} based on the requestDate
// (supports the startDate and endDate being on different days as long as its less than 24hrs)
// assumes that the requestDate's weekday is either similar to the startDate or endDate weekday
export const getNewDatesOnRequestDateByWeekday = (
  requestDate,
  startDate,
  endDate
) => {
  if (!requestDate || !startDate || !endDate) {
    toast.error("missing params (getEventDatesOnRequestDate)");
    return;
  }

  const isStartAndEndWithin24Hrs =
    getTimeDiffInMins(startDate, endDate) < MINUTES_IN_DAY;
  // console.log({
  //   requestDate: moment(requestDate).toString(),
  //   startDate: moment(startDate).toString(),
  //   endDate: moment(endDate).toString(),
  //   isStartAndEndWithin24Hrs,
  // });
  const requestDateWeekday = moment(requestDate).day();
  const startWeekday = moment(startDate).day();
  const startHrs = moment(startDate).hours();
  const startMins = moment(startDate).minutes();
  const endWeekday = moment(endDate).day();
  const endHrs = moment(endDate).hours();
  const endMins = moment(endDate).minutes();

  const isEventStartAndEndOnSameDay = startWeekday === endWeekday;
  const isRequestDaySimilarToStartDay = requestDateWeekday === startWeekday;
  const isRequestDaySimilarToEndDay = requestDateWeekday === endWeekday;

  if (!isRequestDaySimilarToStartDay && !isRequestDaySimilarToEndDay) {
    toast.error(
      "getNewDatesOnRequestDateByWeekday(), the requestDate is in different weekday from startDate or endDate"
    );
    return;
  }
  if (!isStartAndEndWithin24Hrs) {
    toast.error(
      "getNewDatesOnRequestDateByWeekday(), startDate and endDate must be within 24hrs"
    );
    return;
  }

  let newStartDate, newEndDate;
  // if the start and end are on the same day then we use the requestDate and add the time normally
  if (isEventStartAndEndOnSameDay) {
    newStartDate = moment(requestDate)
      .set({
        hours: startHrs,
        minutes: startMins,
        seconds: 0,
        milliseconds: 0,
      })
      .toDate();
    newEndDate = moment(requestDate)
      .set({
        hours: endHrs,
        minutes: endMins,
        seconds: 0,
        milliseconds: 0,
      })
      .toDate();

    // if different days, then check : if the req weekday similar to the start weekday, then the end will be 11:59, else the start will be 0:00
  } else {
    if (isRequestDaySimilarToStartDay) {
      newStartDate = moment(requestDate)
        .set({
          hours: startHrs,
          minutes: startMins,
          seconds: 0,
          milliseconds: 0,
        })
        .toDate();
      newEndDate = moment(requestDate)
        .set({
          hours: 23,
          minutes: 59,
          seconds: 0,
          milliseconds: 0,
        })
        .toDate();
    } else if (isRequestDaySimilarToEndDay && isStartAndEndWithin24Hrs) {
      newStartDate = moment(requestDate)
        .set({
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        })
        .toDate();
      newEndDate = moment(requestDate)
        .set({
          hours: endHrs,
          minutes: endMins,
          seconds: 0,
          milliseconds: 0,
        })
        .toDate();
    } else {
      // this case where the start and end dates are far and the requestDate comes in between them, then the newStartDate,newEndDate will cover the whole requestDate
      newStartDate = moment(requestDate)
        .set({
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        })
        .toDate();
      newEndDate = moment(requestDate)
        .set({
          hours: 23,
          minutes: 59,
          seconds: 0,
          milliseconds: 0,
        })
        .toDate();
    }
  }

  return { newStartDate, newEndDate };
};

export const checkIfCompletedTrialStudent = (trialStudent) => {
  if (!trialStudent) return false;

  const {
    name,
    birthday,
    primaryContactEmail,
    primaryContactName,
    primaryPhone,
  } = trialStudent;

  if (
    !name ||
    !birthday ||
    !primaryContactEmail ||
    !primaryContactEmail ||
    !primaryContactName ||
    !primaryPhone
  )
    return false;

  return true;
};

export const checkIfCompletedPrivateStudent = (privateStudent) => {
  if (!privateStudent) return false;

  const {
    fullName,
    systemID,
    birthday,
    gender,
    enrollmentDate,
    homeAddress,
    state,
    city,
    zip,
    primaryContactEmail,
    primaryContactName,
    primaryPhone,
  } = privateStudent;

  if (
    !fullName ||
    !systemID ||
    !birthday ||
    !gender ||
    !enrollmentDate ||
    !homeAddress ||
    !state ||
    !city ||
    !zip ||
    !primaryContactEmail ||
    !primaryContactEmail ||
    !primaryContactName ||
    !primaryPhone
  )
    return false;

  return true;
};

export const getMakeUpStatus = ({
  isExpired,
  isNotMadeUpFor,
  isFullyScheduled,
  isFullyCompleted,
  isPartiallyScheduled,
  isPartiallyCompleted,
}) => {
  if (isExpired) {
    return absenceMakeUpStatuses.expired.code;
  } else if (isNotMadeUpFor) {
    return absenceMakeUpStatuses.notMadeUpFor.code;
  } else if (isFullyScheduled) {
    return absenceMakeUpStatuses.fullyScheduled.code;
  } else if (isFullyCompleted) {
    return absenceMakeUpStatuses.fullyMadeUpFor.code;
  } else if (isPartiallyScheduled) {
    return absenceMakeUpStatuses.partiallyScheduled.code;
  } else if (isPartiallyCompleted) {
    return absenceMakeUpStatuses.partiallyMadeUpFor.code;
  } else {
    toast.error("Couldnt get an absence status");
    return null;
  }
};

export const getAbsenceMakeUpStatusData = (
  absence,
  absenceMakeUps,
  privateLessonDuration,
  makeUpPeriodDeadline
) => {
  const absenceDate = absence.date || absence.startDate;
  const absenceTotalMakeUpDuration = absenceMakeUps.reduce(
    (acc, currentMakeUp) => {
      return acc + parseInt(currentMakeUp.date?.lessonLength || 0);
    },
    0
  );

  if (makeUpPeriodDeadline) {
    // is expired if it isn't fully completed and either the absence date is after its makeup deadline or today is after the absence's makeup deadline
    const isExpired =
      absenceTotalMakeUpDuration < privateLessonDuration &&
      !isAbsenceBypassMakeupDeadline(absence?.absenceType) &&
      (moment(absenceDate).isAfter(moment(makeUpPeriodDeadline)) ||
        updatedMomentNow().isAfter(makeUpPeriodDeadline));

    const isNotMadeUpFor = absenceTotalMakeUpDuration === 0 && !isExpired;

    const isFullyScheduled =
      absenceTotalMakeUpDuration === privateLessonDuration &&
      absenceMakeUps.some((makeup) =>
        moment(makeup.date.startDate).isAfter(updatedMomentNow())
      );
    const isFullyCompleted =
      absenceTotalMakeUpDuration === privateLessonDuration &&
      absenceMakeUps.every((makeup) =>
        moment(makeup.date.startDate).isBefore(updatedMomentNow())
      );

    const isPartiallyScheduled =
      absenceTotalMakeUpDuration < privateLessonDuration &&
      absenceTotalMakeUpDuration !== 0 &&
      absenceMakeUps.some((makeup) => {
        if (makeup?.date?.startDate) {
          return moment(makeup.date.startDate).isAfter(updatedMomentNow());
        }
      }) &&
      !isExpired;

    const isPartiallyCompleted =
      absenceTotalMakeUpDuration < privateLessonDuration &&
      absenceTotalMakeUpDuration !== 0 &&
      absenceMakeUps.every((makeup) => {
        if (makeup?.date?.startDate) {
          return moment(makeup.date.startDate).isBefore(updatedMomentNow());
        }
      }) &&
      !isExpired;

    // console.log({
    //   privateLessonDuration,
    //   makeUpPeriodDeadline,
    //   absenceTotalMakeUpDuration,
    //   absenceDate: absence.date,
    //   isExpired,
    //   isNotMadeUpFor,
    //   isFullyScheduled,
    //   isFullyCompleted,
    //   isPartiallyScheduled,
    //   isPartiallyCompleted,
    // });

    const absenceMakeUpStatus = getMakeUpStatus({
      isExpired,
      isNotMadeUpFor,
      isFullyScheduled,
      isFullyCompleted,
      isPartiallyScheduled,
      isPartiallyCompleted,
    });

    return {
      absenceTotalMakeUpDuration,
      isExpired,
      isNotMadeUpFor,
      isFullyScheduled,
      isFullyCompleted,
      isPartiallyScheduled,
      isPartiallyCompleted,
      absenceMakeUpStatus,
    };
  } else {
    return {
      absenceMakeUpStatus: absenceMakeUpStatuses.noMakeUpDeadline.code,
    };
  }
};
export const getAgeFromDate = (date) => {
  if (!date) return;
  const exactAge = moment
    .duration({
      from: date,
      to: updatedMomentNow().toDate(),
    })
    .asYears();

  const flooredAge = Math.floor(exactAge);

  return flooredAge;
};

export const getFilePathInfo = (filePath) => {
  if (!filePath) return {};
  const splitPath = filePath.split("/");

  // the type of the file (whether its profileImages, library, etc..)
  const fileType = splitPath[0];

  // file name is the last string in the path
  const fileName = splitPath[splitPath.length - 1];

  let userId, fileSubType;
  if (fileType === "lessonNotes") {
    fileSubType = splitPath[1];
    userId = splitPath[2];
  } else if (fileType === "library") {
    fileSubType = splitPath[2];
    userId = splitPath[1];
  } else {
    userId = splitPath[1];
  }

  return { userId, fileType, fileSubType, fileName };
};

export const canUserUploadFileCheck = (user, fileSize) => {
  if (!user || (!fileSize && fileSize !== 0)) {
    toast.error("Invalid params (canUserUploadFileCheck())");
    return false;
  }

  fileSize = parseInt(fileSize);

  const { hasAvailableCapacity, availableCapacity } =
    checkIfUserHasAvailableStorageCapacity(user);
  console.log({ fileSize, hasAvailableCapacity, availableCapacity });
  // can't upload a file if no available storage capacity
  if (!hasAvailableCapacity) return false;

  if (availableCapacity > fileSize) {
    return true;
  } else {
    return false;
  }
};
export const checkIfUserHasAvailableStorageCapacity = (user) => {
  if (!user) {
    toast.error("Invalid params (checkIfUserHasAvailableStorageCapacity())");
    return;
  }

  let { role, allowedStorageCapacity, storageSize } = user;
  const isStudentUser = isStudent(role);
  const isTeacherUser = isTeacher(role);

  storageSize = storageSize ? storageSize : 0;

  const roleDefaultStorageCapacity = usersDefaultStorageCapacity[role];

  // allowed storage will be either the allowedStorageCapacity if specified , or the default role capacity
  let allowedStorage = allowedStorageCapacity
    ? parseInt(allowedStorageCapacity)
    : roleDefaultStorageCapacity;

  let hasAvailableCapacity = false;
  if (allowedStorage <= storageSize) {
    hasAvailableCapacity = false;
  } else {
    hasAvailableCapacity = true;
  }

  const availableCapacity = allowedStorage - storageSize;
  return { hasAvailableCapacity, availableCapacity };
};

export const calculateFilesSize = (files) => {
  if (!files?.length) return 0;

  console.log("calculateFilesSize()", { files });

  const filesSizeArray = files.map(({ size }) => size);
  const size = filesSizeArray.reduce(
    (firstSize, secondSize) => firstSize + secondSize,
    0
  );

  return size;
};
export const isLibraryItemSharedWithUser = (userId, libraryItem) => {
  if (!userId || !libraryItem) {
    return false;
  }

  const sharedWith = libraryItem.sharedWith || [];

  const isSharedWithUser = sharedWith.includes(userId);

  return isSharedWithUser;
};
export const isLessonNoteSharedWithUser = (userId, lessonNote) => {
  if (!userId || !lessonNote) {
    return false;
  }

  const sharedWith = lessonNote.sharedWith || [];

  const isSharedWithUser = sharedWith.includes(userId);

  return isSharedWithUser;
};

export const injectUserStore = (component) => {
  return compose(inject("UserStore"), observer)(component);
};

// export const checkIfTwoDatesHasSameUTCOffset = (firstDate, secondDate) => {
//   if (!firstDate || !secondDate)
//     throw new Error("invalid params (checkIfTwoDatesHasSameUTCOffset)");

//   const firstDateOffset = moment(firstDate).utcOffset();
//   const secondDateOffset = moment(secondDate).utcOffset();

//   return firstDateOffset === secondDateOffset;
// };

function getTimezoneWithoutDST(requestedTZ) {
  const currentTZ = requestedTZ || moment.tz.guess();
  // const isDST = updatedMomentNow().isDST();

  let newTZ = currentTZ;
  switch (currentTZ) {
    case "America/Los_Angeles":
      // if (isDST) {
      //   newTZ = POSITIVE_7_GMT;
      // } else {
      //   newTZ = POSITIVE_8_GMT;
      // }
      newTZ = POSITIVE_8_GMT;
      break;

    case "America/Chicago":
      newTZ = POSITIVE_6_GMT;
      break;

    case "America/New_York":
      newTZ = POSITIVE_5_GMT;
      break;

    default: {
      break;
    }
  }

  return newTZ;
}
export function setConfiguredTimezone() {
  // gets the timezone that is selected by the user from local storage
  const lsTimezone = window.localStorage.getItem(CURRENT_TZ_LS_PROP_NAME);

  // sets the default timezone to a timezone that has the same offset as the current selected timezone but with ignoring the DST time (Only if our selected timezone has DST)
  const newTZ = getTimezoneWithoutDST(lsTimezone);
  moment.tz.setDefault(newTZ);
}
export function getCurrentMomentTimezoneValue() {
  const currentLocalStorageTZ = window.localStorage.getItem(
    CURRENT_TZ_LS_PROP_NAME
  );

  // moment().tz("America/Los_Angeles").format("z"); // PST or PDT

  let longName;
  if (currentLocalStorageTZ) {
    longName = currentLocalStorageTZ;
  } else {
    longName = moment.tz.guess();
  }

  const shortName = moment().tz(longName).format("z");

  const originalName = longName;
  if (!currentLocalStorageTZ) {
    longName = "Local Timezone: " + longName;
  }
  return { originalName, longName, shortName };
}

export const isDisabledTeacherDay = (role, teacherDay) => {
  if (!role || !teacherDay) return;
  // if parent role, then deal with it as the student role
  if (role === UserRole.PARENT) {
    role = UserRole.STUDENT;
  }

  const isDisabled = teacherDay.disabledForRoles?.includes(role);

  return isDisabled;
};

// sorts array of objects by date
export const sortArrayByDates = (
  arr = [],
  options = { asc: true, dateAccessor: "" }
) => {
  if (!arr?.length) return [];

  const { dateAccessor, asc } = options;

  if (!dateAccessor) return arr;

  const arrCopy = [...arr];
  const sorted = arrCopy.sort((a, b) => {
    const firstDate = a[dateAccessor];
    const secondDate = b[dateAccessor];
    if (asc) {
      return getTimeDiffInMins(secondDate, firstDate);
    } else {
      return getTimeDiffInMins(firstDate, secondDate);
    }
  });

  return sorted;
};

export const haveFeatureExtraFunction = (
  userExtraFunctions,
  extraFunctionPropName
) => {
  return userExtraFunctions?.includes(extraFunctionPropName);
};
export const canManageUsersPayment = (user) => {
  if (!user) return false;

  const isAdminUser = isAdmin(user.role);
  const isSuperAdminUser = isSuperAdmin(user.role);

  let canManagePayments = false;

  if (isSuperAdminUser) {
    canManagePayments = true;
  } else if (isAdminUser) {
    canManagePayments = haveFeatureExtraFunction(
      user?.extraFunctions,
      adminsExtraFunctions.manageUsersPayments
    );
  }

  return canManagePayments;
};
// this function checks if the user is a super admin or an admin with extra functionality to have a specific feature that a superadmin has
export const haveSuperAdminPermission = (user, extraFunctionPropName) => {
  if (!user) return false;
  const isSuperAdminUser = isSuperAdmin(user.role);

  const isAdminWithExtraFunctionAllowed =
    isAdmin(user.role) &&
    haveFeatureExtraFunction(user.extraFunctions, extraFunctionPropName);

  return isSuperAdminUser || isAdminWithExtraFunctionAllowed;
};
export const mapPackageActivityToActivitiesArr = (packageActivity) => {
  if (!packageActivity) return [];
  const isPackageActivity = isPackagePrivateLesson(
    packageActivity.privateLessonType
  );
  if (!isPackageActivity) return [];

  const { packageItems } = packageActivity;

  const activitiesArr =
    packageItems?.map((item) => {
      return {
        ...packageActivity,
        ...item,
        docId: packageActivity.id, // bec package item has its own id which will override the main obj id
      };
    }) || [];

  return activitiesArr;
};
export const mapGroupClassActivityToActivitiesArr = (groupClassActivity) => {
  if (!groupClassActivity) return [];
  const isGroupClass = isGroupClassEvent(groupClassActivity.type);

  // making sure the activity is a GC
  if (!isGroupClass) return [];

  const { groupClassItems } = groupClassActivity;

  const activitiesArr =
    groupClassItems?.map((item) => {
      return {
        ...groupClassActivity,
        ...item,
        docId: groupClassActivity.id, // bec package item has its own id which will override the main obj id
      };
    }) || [];

  return activitiesArr;
};

// prepares the teacher activities by converting the package items and groupClasses into an array of single user activities
export const prepareTeacherActivities = (teacherActivities, teacherId) => {
  if (!teacherActivities?.length || !teacherId) return [];
  const modifiedTeacherActivities = [];
  for (const activity of teacherActivities) {
    if (isPackagePrivateLesson(activity.privateLessonType)) {
      const separatedPackageActivities =
        mapPackageActivityToActivitiesArr(activity);
      const sameTeacherActivities = separatedPackageActivities.filter(
        ({ userId }) => userId === teacherId
      );

      modifiedTeacherActivities.push(...sameTeacherActivities);
    } else if (isGroupClassEvent(activity.type)) {
      const separatedGroupClassItems =
        mapGroupClassActivityToActivitiesArr(activity);
      const sameTeacherActivities = separatedGroupClassItems.filter(
        ({ usersIds }) => usersIds.includes(teacherId)
      );

      modifiedTeacherActivities.push(...sameTeacherActivities);
    } else {
      modifiedTeacherActivities.push(activity);
    }
  }
  return modifiedTeacherActivities;
};
export const getPackageItemByDate = ({
  combinedPackageItems,
  startDateAccessor = "",
  date,
  granularity = "minute",
}) => {
  if (!combinedPackageItems?.length || !date) return;
  const packageItem = combinedPackageItems?.find((item) => {
    const isSameDate = moment(date).isSame(
      item[startDateAccessor],
      granularity
    );
    return isSameDate;
  });

  return packageItem;
};
export const getPrivateLessonInfoOnSpecificDate = ({
  privateLesson,
  date,
  withTimelineApproximation = false,
}) => {
  if (!date || !privateLesson) return;
  let startDate, duration, teacherId, locationId, isVirtual;
  if (isPackagePrivateLesson(privateLesson.type)) {
    const packageItem = getPackageItemByDate({
      combinedPackageItems: getCombinedPackageSetItems(
        privateLesson.packageSets
      ),
      startDateAccessor: "startDate",
      date,
      granularity: "date",
    });
    startDate = packageItem?.startDate;
    duration = packageItem?.duration;
    teacherId = packageItem?.teacherId;
    locationId = packageItem?.locationId;
    isVirtual = packageItem?.isVirtual;
  } else {
    const timelineOnRequestedDate = getRecurringEventTimelineObj({
      timeline: privateLesson.timeline,
      startDateAccessor: "startDate",
      lastDateAccessor: "lastDate",
      requestDate: date,
      withApproximation: withTimelineApproximation,
    });
    startDate = timelineOnRequestedDate?.startDate;
    duration = timelineOnRequestedDate?.duration;
    teacherId = timelineOnRequestedDate?.teacherId;
    locationId = timelineOnRequestedDate?.locationId;
    isVirtual = timelineOnRequestedDate?.isVirtual;
  }

  return { startDate, duration, teacherId, locationId, isVirtual };
};
export const checkForObjectChanges = (initialObj, editedObj) => {
  const hasObjChanged =
    JSON.stringify(initialObj) !== JSON.stringify(editedObj);
  return hasObjChanged;
};

export const getAbsencePackageItem = ({
  lessonId,
  combinedPackageItems,
  startDateAccessor,
  absence,
}) => {
  if (!combinedPackageItems?.length || !startDateAccessor || !absence) return;

  const { absenceBehaviour } = absence;
  const isPerLesson = isPerLessonAbsence(absenceBehaviour);

  let foundPackageItem;
  for (const packageItem of combinedPackageItems) {
    const packageItemStartDate = packageItem[startDateAccessor];
    const duration = packageItem.duration;
    const packageItemEndDate = packageItemStartDate
      ? moment(packageItemStartDate).add(duration, "minutes")
      : null;

    let foundAbsence = false;
    if (isPerLesson) {
      foundAbsence =
        moment(absence.date).isSame(packageItemStartDate, "minute") &&
        absence.lessonId === lessonId;
    } else {
      foundAbsence =
        areTwoEventsOverlapping(
          {
            start_time: packageItemStartDate,
            end_time: packageItemEndDate,
            isRecurring: false,
          },
          {
            start_time: absence.startDate,
            end_time: absence.endDate,
            isRecurring: false,
          }
        ) && absence.affectedPrivateLessonsIds?.includes(lessonId);
    }
    if (foundAbsence) {
      foundPackageItem = packageItem;
      break;
    }
  }

  return foundPackageItem;
};

export const getPackageLessonSetsNumbers = (packageSets) => {
  if (!packageSets?.length) return [];

  const sets = packageSets.map(({ setNumber }) => setNumber);
  return sets;
};

export function getPackageLessonSetExpirationDate(packageSet) {
  if (!packageSet) return;

  const setSortedItems = sortArrayByDates(packageSet.setItems, {
    asc: true,
    dateAccessor: "startDate",
  });

  const firstSetLessonDate = setSortedItems[0]?.startDate;

  const setDeadline =
    packageSet.expirationDate ||
    moment(firstSetLessonDate).add(6, "months").toDate();

  return setDeadline;
}
export const isPassedPackageLessonSetDeadline = (packageSet) => {
  if (!packageSet) return;

  const setDeadline = getPackageLessonSetExpirationDate(packageSet);

  const passed = updatedMomentNow().isAfter(setDeadline, "date");

  return passed;
};

export const getLessonPackageItemsInfo = (packageSets = [], requiredSet) => {
  packageSets = packageSets || [];
  // getting last set
  const lessonSets = getPackageLessonSetsNumbers(packageSets);
  const lastSetNumber = Math.max(...(lessonSets || [])) || 0;
  const lastSetObj = packageSets?.find(
    ({ setNumber }) => setNumber === lastSetNumber
  );

  // last set info
  const lastSetItems = lastSetObj?.setItems || [];

  const lastSetSortedItems = sortArrayByDates(lastSetItems, {
    asc: true,
    dateAccessor: "startDate",
  });
  const lastSetFirstItem = lastSetSortedItems[0];
  const lastSetLastItem = lastSetSortedItems[lastSetSortedItems.length - 1];

  const isPassedLastSetDeadline = isPassedPackageLessonSetDeadline(lastSetObj);
  const isFullLastSet = lastSetItems.length >= MAX_ITEMS_IN_PACKAGE_LESSON_SET;

  //required set info (OPTIONAL)(Only if requiredSet is provided)
  let requiredSetItems,
    requiredSetFirstItem,
    requiredSetLastItem,
    isPassedRequiredSetDeadline,
    isFullRequiredSet;
  if (requiredSet) {
    const requiredSetObj = packageSets?.find(
      ({ setNumber }) => setNumber === requiredSet
    );
    requiredSetItems = requiredSetObj?.setItems || [];
    const requiredSetSortedItems = sortArrayByDates(requiredSetItems, {
      asc: true,
      dateAccessor: "startDate",
    });
    requiredSetFirstItem = requiredSetSortedItems[0];
    requiredSetLastItem =
      requiredSetSortedItems[requiredSetSortedItems?.length - 1];

    isPassedRequiredSetDeadline =
      isPassedPackageLessonSetDeadline(requiredSetObj);
    isFullRequiredSet =
      requiredSetItems.length >= MAX_ITEMS_IN_PACKAGE_LESSON_SET;
  }

  // whether we can renew the lesson or not based on the last set info, if there is no last set (in case the lesson has no set items), then renew is available
  const isRenewAvailable = lastSetObj
    ? isPassedLastSetDeadline || isFullLastSet
    : true;

  return {
    lessonSets,
    isRenewAvailable,
    lastSetInfo: {
      set: lastSetNumber,
      setItems: lastSetItems,
      firstItem: lastSetFirstItem,
      lastItem: lastSetLastItem,
      isPassedSetDeadline: isPassedLastSetDeadline,
      isFullSet: isFullLastSet,
    },
    requiredSetInfo: {
      ...(requiredSet && {
        set: requiredSet,
        setItems: requiredSetItems,
        firstItem: requiredSetFirstItem,
        lastItem: requiredSetLastItem,
        isPassedSetDeadline: isPassedRequiredSetDeadline,
        isFullSet: isFullRequiredSet,
      }),
    },
  };
};

export const getInitialsImageUrl = (name) => {
  const url = `https://ui-avatars.com/api/?name=${
    name || "John Doe"
  }&color=fff&background=c2185b`;

  return url;
};

export const updatedNow = () => {
  const reqDate = moment().toDate();

  const { originalName } = getCurrentMomentTimezoneValue();
  const isDST = moment.tz(reqDate, originalName).isDST();

  const updatedDateValue = isDST
    ? moment(reqDate).add(1, "hours").toDate()
    : reqDate;

  return updatedDateValue;
};
export const updatedMomentNow = () => {
  return moment(updatedNow());
};
export function capitalizeFirstLetter(string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getUserPaymentRatesTitles(user) {
  if (!user) return [];

  let userPaymentRates = [];
  if (hasAdminCredentials(user.role)) {
    userPaymentRates =
      user.workCondition === workConditions.fullTime.value
        ? fullTimeAdminRates
        : partTimeAdminRates;
  } else if (isTeacher(user.role)) {
    userPaymentRates =
      user.workCondition === workConditions.fullTime.value
        ? fullTimeTeacherRates
        : partTimeTeacherRates;
  }
  return userPaymentRates;
}
export function getUserPaymentProps(user) {
  if (!user) return [];

  let userPaymentProps = [];
  if (hasAdminCredentials(user.role)) {
    userPaymentProps =
      user.workCondition === workConditions.fullTime.value
        ? fullTimeAdminPaymentProps
        : partTimeAdminPaymentProps;
  } else if (isTeacher(user.role)) {
    userPaymentProps =
      user.workCondition === workConditions.fullTime.value
        ? fullTimeTeacherPaymentProps
        : partTimeTeacherPaymentProps;
  }
  return userPaymentProps;
}

export function calculateTotalFromPaymentForm({ paymentFormData, userRates }) {
  userRates = userRates || [];

  let individualPaymentsTotal = {};
  for (const map of paymentsMap) {
    const {
      propName,
      rateTitle,
      rateType,
      fractionPerUnit,
      paymentCalculationType,
    } = map;

    const paymentFieldValue = paymentFormData[propName];

    if (!paymentFieldValue) {
      continue;
    }

    let paymentValue;
    if (
      [paymentCalculationTypes.CUSTOM, paymentCalculationTypes.FIXED].includes(
        paymentCalculationType
      )
    ) {
      paymentValue = roundToNearestDecimals(paymentFieldValue, 2);
    } else if (
      [paymentCalculationTypes.COUNT, paymentCalculationTypes.HOUR].includes(
        paymentCalculationType
      )
    ) {
      const currentRateObj = userRates.find(
        ({ title, type }) => title === rateTitle && type === rateType
      );

      if (!currentRateObj) {
        continue;
      }

      const rateValuePerUnit = currentRateObj.value * fractionPerUnit;

      // payment value is the rate * the payment field value rounded to the nearest 2 decimals
      paymentValue = roundToNearestDecimals(
        rateValuePerUnit * paymentFieldValue,
        2
      );
    }
    individualPaymentsTotal = {
      ...individualPaymentsTotal,
      [propName]: paymentValue,
    };
  }

  // const total = Math.round(
  //   Object.values(individualPaymentsTotal).reduce((acc, curr) => acc + curr, 0)
  // );
  const total = roundToNearestDecimals(
    Object.values(individualPaymentsTotal).reduce((acc, curr) => acc + curr, 0),
    2
  );

  return { total, individualPaymentsTotal };
}
export function handleCopyText(text) {
  // Copy the text inside the text field
  navigator.clipboard.writeText(text);

  toast.success("Copied !");
}
export function momentPayment(date, dateFormat) {
  let modified;
  if (date) {
    if (dateFormat) {
      modified = moment.tz(date, dateFormat, paymentDatesTimezone);
    } else {
      modified = moment.tz(date, paymentDatesTimezone);
    }
  } else {
    modified = moment.tz(paymentDatesTimezone);
  }
  return modified;
}
export function convertPaymentDateToDateStr(date) {
  const dateStr = momentPayment(date).format(paymentDatesFormat);

  return dateStr;
}
export function convertPaymentDateStrToDate(dateStr) {
  const date = momentPayment(dateStr, paymentDatesFormat)
    .set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
    .toDate();

  return date;
}
export function checkIfActivityDuringSchoolBreak({ start }, schoolBreak) {
  if (!start || !schoolBreak) {
    toast.error("invalid params checkIfActivityDuringSchoolBreak");
    return false;
  }

  const duringBreak = moment(start).isBetween(
    schoolBreak.from.toDate(),
    schoolBreak.to.toDate(),
    "minutes",
    "[)"
  );

  return duringBreak;
}

//event={start:new Date}
export function getNearestFutureEvent(events) {
  if (!events?.length) return [];

  let nextEvent = null;
  for (const event of events) {
    const { startDate } = event;
    const diff1 = getTimeDiffInMins(updatedMomentNow().toDate(), startDate);
    const diff2 = nextEvent
      ? getTimeDiffInMins(updatedMomentNow().toDate(), nextEvent.startDate)
      : -1;

    if (diff1 < 0) {
      continue;
    } else if (diff2 < 0) {
      nextEvent = event;
    } else if (diff1 < diff2) {
      nextEvent = event;
    }
  }

  return nextEvent;
}

//decimals can only by (1 or 2 or 3)
export function roundToNearestDecimals(number, decimals) {
  if (!number) return;
  const decimalMultiplier =
    decimals === 1 ? 10 : decimals === 2 ? 100 : decimals === 3 ? 1000 : 1;

  return (
    Math.round((number + Number.EPSILON) * decimalMultiplier) /
    decimalMultiplier
  );
}
export function mapSummerBreakToCanceledRange(summerBreakObj) {
  if (!summerBreakObj) return;
  const { breakStart, breakEnd } = summerBreakObj;

  const canceledRange = {
    start: breakStart,
    ...(breakEnd && {
      end: breakEnd,
    }),
  };

  return canceledRange;
}
export function calculatePaymentTotal(payments) {
  if (!payments?.length) return 0;

  const paymentTotal = payments.reduce((acc, { totalPaid }) => {
    return acc + totalPaid;
  }, 0);

  const rounded = roundToNearestDecimals(paymentTotal, 2);

  return rounded;
}

export function isActiveTeacher(user) {
  const isTeacherUser = isTeacher(user?.role);
  if (!isTeacherUser) return false;

  const isDisabled = isDisabledUser(user);
  if (isDisabled) return false;

  return true;
}

export function isDisabledUser(user, requestDate = updatedMomentNow()) {
  const { disableObj } = user || {};
  const { disabledAt } = disableObj || {};

  if (!disabledAt) return false;

  const parsedDisabledAt =
    disabledAt instanceof Date ? disabledAt : disabledAt.toDate();

  const isDisabled = moment(requestDate).isSameOrAfter(
    parsedDisabledAt,
    "minutes"
  );

  return isDisabled;
}

export function isDuringSummerBreak(date, summerBreak) {
  if (!date || !summerBreak) return false;

  const { breakStart, breakEnd } = summerBreak;
  if (!breakStart) return false;

  const isDateDuringBreak = breakEnd
    ? moment(date).isBetween(breakStart, breakEnd, "minutes", "[]")
    : moment(date).isAfter(breakStart, "minutes");

  return isDateDuringBreak;
}
export function mapRegularLessonTimelineToActivity(timelineObj) {
  if (!timelineObj) return;

  const lastDate = timelineObj.lastDate;
  const activityStart = timelineObj.startDate;
  const lessonDuration = timelineObj.duration;
  const activityEnd = moment(activityStart)
    .add(lessonDuration, "minutes")
    .toDate();

  const activityTimelineObj = {
    id: timelineObj.id, // same id that the timeline has in the original doc
    start_time: activityStart, // first lesson date
    end_time: activityEnd,
    location: timelineObj.locationId,
    userId: timelineObj.teacherId,
    userRole: UserRole.TEACHER,
    ...(lastDate && {
      lastDate: lastDate,
    }),
  };

  return activityTimelineObj;
}

export function checkIfSummerBreakReturnIsScheduled(summerBreak, timeline) {
  if (!summerBreak || !timeline?.length) return false;

  const summerBreakEnd = summerBreak.breakEnd;
  if (!summerBreakEnd) return false;

  const breakReturnTimeline = timeline.find((timelineObj) => {
    const timelineStart = timelineObj.startDate;
    const isSameDate = moment(summerBreakEnd).isSame(timelineStart, "minutes");
    return isSameDate;
  });

  return !!breakReturnTimeline;
}
export function getTheTimelineObjBeforeSummerBreak(summerBreak, timeline) {
  if (!summerBreak || !timeline?.length) return;

  const summerBreakStart = summerBreak.breakStart;
  if (!summerBreakStart) return false;

  const combinedList = [...timeline, summerBreak].sort((a, b) => {
    const firstDate = a.startDate || a.breakStart;
    const secondDate = b.startDate || b.breakStart;

    return getTimeDiffInMins(secondDate, firstDate);
  });

  const indexOfSummerBreak = combinedList.findIndex(
    ({ id }) => id === summerBreak.id
  );
  const foundTimelineObj = combinedList[indexOfSummerBreak - 1];

  return foundTimelineObj;
}
export function checkIfTimelineObjIsSummerBreakReturn(
  timelineObj,
  summerBreaks
) {
  if (!timelineObj || !summerBreaks?.length) return false;

  const { startDate: timelineStart } = timelineObj;

  const isBreakReturn = summerBreaks.find((summerBreak) => {
    const { breakEnd } = summerBreak;
    if (!breakEnd) return false;

    const isSameDate = moment(breakEnd).isSame(timelineStart, "minutes");
    return isSameDate;
  });

  return isBreakReturn;
}

export const sortSchoolYears = (schoolYears, isDesc = false) => {
  const sortedSchoolYears = schoolYears?.sort((a, b) => {
    const firstDate = Object.values(a?.locations || {})[0]?.dates?.start_date;
    const secondDate = Object.values(b?.locations || {})[0]?.dates?.start_date;

    const firstYearDate = firstDate ? firstDate.toDate() : null;
    const secondYearDate = secondDate ? secondDate.toDate() : null;
    return (
      firstYearDate &&
      secondYearDate &&
      getTimeDiffInMins(
        isDesc ? firstYearDate : secondYearDate,
        isDesc ? secondYearDate : firstYearDate
      )
    );
  });

  return sortedSchoolYears;
};

// the input start_time and end_time value must be in dates not strings but i just put the time string for visual simplicity
// const firstDateRangesInput = [
//   {
//     start_time: "12:00 AM",
//     end_time: "07:00 AM",
//   },
//   {
//     start_time: "07:30 AM",
//     end_time: "09:00 PM",
//   },
// ];
// const secondDateRangesInput = [
//   {
//     start_time: "12:00 AM",
//     end_time: "04:00 AM",
//   },
//   {
//     start_time: "04:30 AM",
//     end_time: "09:00 PM",
//   },
// ];

// const output = [
//   {
//     start_time: "12:00 AM",
//     end_time: "04:00 AM",
//   },
//   {
//     start_time: "4:30 AM",
//     end_time: "07:00 AM",
//   },
//   {
//     start_time: "07:30 AM",
//     end_time: "09:00 PM",
//   }
// ]

export const flattenDateRanges = (
  firstDateRanges,
  secondDateRanges,
  requestDate = moment().toDate()
) => {
  if (!firstDateRanges?.length || !secondDateRanges?.length) return [];

  const sortedFirstDateRanges = firstDateRanges
    .map(({ start_time, end_time }) => ({
      start_time: moment(start_time)
        .set({
          year: moment(requestDate, "YYYY-MM-DD").year(),
          month: moment(requestDate, "YYYY-MM-DD").month(),
          date: moment(requestDate, "YYYY-MM-DD").date(),
          second: 0,
          millisecond: 0,
        })
        .toDate(),
      end_time: moment(end_time)
        .set({
          year: moment(requestDate, "YYYY-MM-DD").year(),
          month: moment(requestDate, "YYYY-MM-DD").month(),
          date: moment(requestDate, "YYYY-MM-DD").date(),
          second: 0,
          millisecond: 0,
        })
        .toDate(),
    }))
    .sort(
      (a, b) =>
        moment(a.start_time).set({ second: 0, millisecond: 0 }) -
        moment(b.start_time).set({ second: 0, millisecond: 0 })
    );
  const sortedSecondDateRanges = secondDateRanges
    .map(({ start_time, end_time }) => ({
      start_time: moment(start_time)
        .set({
          year: moment(requestDate, "YYYY-MM-DD").year(),
          month: moment(requestDate, "YYYY-MM-DD").month(),
          date: moment(requestDate, "YYYY-MM-DD").date(),
          second: 0,
          millisecond: 0,
        })
        .toDate(),
      end_time: moment(end_time)
        .set({
          year: moment(requestDate, "YYYY-MM-DD").year(),
          month: moment(requestDate, "YYYY-MM-DD").month(),
          date: moment(requestDate, "YYYY-MM-DD").date(),
          second: 0,
          millisecond: 0,
        })
        .toDate(),
    }))
    .sort(
      (a, b) =>
        moment(a.start_time).set({ second: 0, millisecond: 0 }) -
        moment(b.start_time).set({ second: 0, millisecond: 0 })
    );

  const firstStartTime = new Date(
    Math.min(
      ...[...sortedFirstDateRanges, ...sortedSecondDateRanges].map(
        ({ start_time }) => start_time
      )
    )
  );
  const lastEndTime = new Date(
    Math.max(
      ...[...sortedFirstDateRanges, ...sortedSecondDateRanges].map(
        ({ end_time }) => end_time
      )
    )
  );

  const minutesBetweenMinAndMaxDate = getTimeDiffInMins(
    firstStartTime,
    lastEndTime
  );

  const minuteByMinuteDatesArr = [];
  for (
    let minutesValue = 0;
    minutesValue <= minutesBetweenMinAndMaxDate;
    minutesValue++
  ) {
    const date = moment(firstStartTime).add(minutesValue, "minutes").toDate();
    minuteByMinuteDatesArr.push(date);
  }

  const availableDates = [];
  for (const date of minuteByMinuteDatesArr) {
    const isDateIncludedInFirstRanges = sortedFirstDateRanges.some((range) =>
      moment(date).isBetween(range.start_time, range.end_time, "[]", "minutes")
    );
    const isDateIncludedInSecondRanges = sortedSecondDateRanges.some((range) =>
      moment(date).isBetween(range.start_time, range.end_time, "[]", "minutes")
    );
    if (isDateIncludedInFirstRanges && isDateIncludedInSecondRanges) {
      availableDates.push(date);
    }
  }

  const rangesFromConsecutiveDates =
    combineConsecutiveDatesToRanges(availableDates);

  return rangesFromConsecutiveDates;
};

// combines dates to date ranges if there is 1 minute diff between consecutive dates
export function combineConsecutiveDatesToRanges(dates) {
  if (!dates?.length) return [];

  const ranges = dates
    .reduce(
      (acc, date) => {
        const currentObj = acc[acc.length - 1];
        if (getTimeDiffInMins(currentObj.end_time || date, date) > 1) {
          acc.push({ start_time: date });
        } else {
          currentObj.end_time = date;
        }
        return acc;
      },
      [{ start_time: dates[0] }]
    )
    // makes sure we dont end up with date ranges that have same start and end time
    .filter(
      (range) => !moment(range.start_time).isSame(range.end_time, "minutes")
    );
  return ranges;
}
export function getCombinedPackageSetItems(packageSets) {
  if (!packageSets?.length) return [];

  const combinedPackageItems = packageSets
    .map(({ setItems }) => setItems)
    .flat();

  return combinedPackageItems;
}

export function isFutureAndAfterRequestDateActivity(requestDate, activityDate) {
  if (!requestDate || !activityDate) return false;

  const isFuture = updatedMomentNow().isBefore(activityDate, "minutes");
  const isAfterRequestDate = moment(requestDate).isBefore(
    activityDate,
    "minutes"
  );

  return isFuture && isAfterRequestDate;
}
export function getGroupClassClassesByFromDates({ classObj, classDates }) {
  if (!classObj || !classDates?.length) {
    return;
  }
  const lessonStart = classObj.startDate;

  const classes = [];
  for (let i = 0; i < classDates.length; i++) {
    const currentDate = classDates[i];
    const updatedStartDate = moment(currentDate)
      .set({
        hours: moment(lessonStart).hours(),
        minutes: moment(lessonStart).minutes(),
        seconds: 0,
        millisecond: 0,
      })
      .toDate();
    const updatedClassObj = {
      ...classObj,
      id: uuidv4(),
      startDate: updatedStartDate,
    };
    classes.push(updatedClassObj);
  }

  return classes;
}

export function isGroupClassInLocation(groupClassObj, locationId) {
  if (!groupClassObj || !locationId) return false;

  const { classes } = groupClassObj;
  const isInLocation = classes.some(
    (classObj) => classObj.locationId === locationId
  );

  return isInLocation;
}
export function isGroupClassInDate(
  groupClassObj,
  requestStartDate,
  granularity = "date"
) {
  if (!groupClassObj || !requestStartDate) return false;

  const { classes } = groupClassObj;
  const isInDate = classes.some((classObj) =>
    moment(classObj.startDate).isSame(requestStartDate, granularity)
  );

  return isInDate;
}

export function getUpcomingGroupClassLesson(classes) {
  if (!classes?.length) return;

  const sortedClasses = sortArrayByDates(classes, {
    asc: true,
    dateAccessor: "startDate",
  });
  const nextClass =
    getNearestFutureEvent(sortedClasses) ||
    sortedClasses[sortedClasses.length - 1];

  return nextClass;
}

export function isClassIncludedInRegularEnrollment(enrollment, classObj) {
  const { enrollmentDate } = enrollment || {};
  const { startDate: classStart } = classObj || {};
  if (!enrollment || !classObj || !enrollmentDate || !classStart) return false;

  const isClassAfterEnrollmentDate = moment(classStart).isAfter(
    enrollmentDate,
    "minutes"
  );

  return isClassAfterEnrollmentDate;
}

export function isStudentEnrolledInClass(enrollment, classObj) {
  if (!enrollment || !classObj) return false;

  const isRegularEnrollment = isRegularGroupClassEnrollment(
    enrollment.enrollmentType
  );

  let isEnrolled = false;
  if (isRegularEnrollment) {
    const isClassIncludedInEnrollment = isClassIncludedInRegularEnrollment(
      enrollment,
      classObj
    );
    isEnrolled = isClassIncludedInEnrollment;
  } else {
  }
  return isEnrolled;
}

export function getUpcomingGroupClassEnrollments(classes, enrollments) {
  if (!classes?.length || !enrollments?.length) return [];

  const upcomingClass = getUpcomingGroupClassLesson(classes);

  const upcomingClassStudents = enrollments.filter((enrollment) =>
    isStudentEnrolledInClass(enrollment, upcomingClass)
  );

  return upcomingClassStudents;
}

export function getGroupClassPaymentOptionsTotal(optionPayments) {
  if (!optionPayments?.length) return 0;

  const total = optionPayments?.reduce(
    (accumulator, currentValue) => accumulator + currentValue?.amount,
    0
  );

  return total;
}
export function getPaypalOrderTotal(purchase_units) {
  if (!purchase_units?.length) return 0;

  const total = purchase_units?.reduce(
    (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue?.amount.value),
    0
  );

  return total;
}

export const isFileValidated = (
  file,
  allowedFileTypes = ["image/", "application/pdf", "video/"]
) => {
  if (!file) return;

  let validated = false;
  for (let filetype of allowedFileTypes) {
    if (file.type?.includes(filetype)) {
      validated = true;
      break;
    }
  }
  return validated;
};
export function isConcertInvitationPendingStudentAction(invitation, userId) {
  if (!invitation) return false;
  const {
    signupOption,
    status,
    usersResponses,
    invitedUsersIds = [],
  } = invitation;

  const isTeacherRecommendation = isTeacherRecommendationConcert(
    signupOption?.value
  );
  const isFirstStage =
    status === concertInvitationStatuses.TEACHER_RECOMMENDATION_CREATED;
  const isUserInvited = invitedUsersIds?.includes(userId);
  const hasStudentAlreadyResponded = !!usersResponses?.[userId];

  return (
    isTeacherRecommendation &&
    isFirstStage &&
    isUserInvited &&
    !hasStudentAlreadyResponded
  );
}

export function getTotalConcertProgramDuration(program) {
  if (!program || !program.repertoires?.length) return 0;

  let mins = 0;
  for (const rep of program.repertoires) {
    mins += parseInt(rep.duration);
  }

  return mins;
}
export function shouldTeacherSeeConcertSignup({ signup, pls, teacherId }) {
  if (!signup || !pls?.length || !teacherId) return false;

  const userPLs = pls.filter(({ studentId }) => studentId === signup.userId);
  const isSameTeacher = userPLs.some(({ teachersIds }) =>
    teachersIds?.includes(teacherId)
  );
  return isSameTeacher;
}
export function shouldTeacherSeeConcertProgram({ program, pls, teacherId }) {
  if (!program || !pls?.length || !teacherId) return false;

  const programUserId = program.usersIds?.[0];

  const userPLs = pls.filter(({ studentId }) => studentId === programUserId);
  const isSameTeacher = userPLs.some(({ teachersIds }) =>
    teachersIds?.includes(teacherId)
  );
  return isSameTeacher;
}

export function formatTimestamp(timestamp) {
  const milliseconds =
    timestamp?.seconds * 1000 + Math.round(timestamp?.nanoseconds / 1e6);

  return new Date(milliseconds).toLocaleDateString("en-US");
}
