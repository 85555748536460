import React, { useContext, useEffect, useState } from "react";
import { StudentsContext, StudentsSelectContext, studentViews, StudentsSearchContext } from "../../../common/Library/common/libraryContext";

import { PrimaryButtonCustome } from "../../../../utils/shared/styled";
import TeacherLibrarySearchInput from "../../../common/Library/common/TeacherLibrarySearchInput";
import {
  ProfileLibraryStudentsListContainer,
} from "../../../common/styled-components/teacherLibraryStyledComponents";
import SelectAllStudents from "../../../common/Library/tab-content/students-list/SelectAllStudents";
import LibrarySingleStudentRow from "../../../common/Library/tab-content/students-list/LibrarySingleStudentRow";
import EmptyResults from "../../../common/Library/common/EmptyResults";
import { Select } from "antd";
import { getDocs } from "firebase/firestore";
import { getFirebaseDoc } from "../../../../utils/getFirebaseDoc";
import { FirebaseContext } from "../../../Firebase";

const ProfileLibraryStudentsList = ({ fullWidth, withToggle = true }) => {
  const { select, selected, clear } = useContext(StudentsSelectContext);
  const firebase = useContext(FirebaseContext);
  const { students, currentStudentsView, teacherStudents } =
    useContext(StudentsContext);
  const [studentsSearchValue, setStudentsSearchValue] = useState("");

  const showTeacherStudents =
    currentStudentsView === studentViews.TEACHER_STUDENTS;
  const displayedStudents = showTeacherStudents ? teacherStudents : students;

  const studentsSearchContextValue = {
    studentsSearchValue,
    setStudentsSearchValue,
  };
  const filteredStudents =
    studentsSearchValue === ""
      ? displayedStudents
      : displayedStudents.filter(({ fullName }) =>
        fullName.toLowerCase().includes(studentsSearchValue?.toLowerCase())
      );

  const onChangeSelectTeacher = (id) => {
    if (selected.includes(id)) {
      console.log("click")
      clear();
    } else {
      select(id);
    }
  };

  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [locations, setLocations] = useState([]);

  const onChangeLocation = (value) => {
    console.log(`selected ${value}`);
  };

  const onSearchLocation = (value) => {
    console.log('search:', value);
  };

  const getLocations = async () => {
    const firebaseRequests = [
      getDocs(firebase.locations()),
    ];
    const [
      locationsSnapshot,
    ] = await Promise.all(firebaseRequests);
    const locations = getFirebaseDoc(locationsSnapshot);
    setLocations(Object.values(locations));
    // console.log("locations", locations)
  }

  useEffect(() => {
    getLocations();
  }, []);

  return (
    <StudentsSearchContext.Provider value={studentsSearchContextValue}>
      <ProfileLibraryStudentsListContainer className="mt-5" fullWidth={fullWidth}>
        <div className="mb-3">
          <TeacherLibrarySearchInput
            query={studentsSearchValue}
            setQuery={setStudentsSearchValue}
          />
        </div>
        {/*<div className="mb-3">*/}
        {/*  <Select*/}
        {/*    showSearch*/}
        {/*    placeholder="Select a person"*/}
        {/*    optionFilterProp="label"*/}
        {/*    onChange={onChangeLocation}*/}
        {/*    onSearch={onSearchLocation}*/}
        {/*    options={locations?.map((location) => ({*/}
        {/*      value: location?.id,*/}
        {/*      label: location?.name,*/}
        {/*    })) || []}*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="d-flex justify-content-end mb-2">
          <PrimaryButtonCustome
            backgroundColor="#e357a2"
            style={{ fontSize: "1rem" }}
            width="83px"
            height="30px"
            onClick={clear}
          >
            Clear
          </PrimaryButtonCustome>
        </div>

        {studentsSearchValue === "" && withToggle ? (
          <SelectAllStudents />
        ) : null}
        {filteredStudents.map(({ id, fullName }, index) => (
          <LibrarySingleStudentRow
            id={id}
            key={id || index}
            checked={selected.includes(id)}
            onChange={() => onChangeSelectTeacher(id)}
            label={fullName}
          />
        ))}
        {filteredStudents.length === 0 && studentsSearchValue !== "" && (
          <EmptyResults />
        )}
      </ProfileLibraryStudentsListContainer>
    </StudentsSearchContext.Provider>
  );
};
export default ProfileLibraryStudentsList;
