import React, { useState, useEffect, useContext } from "react";
import RouteContent from "../../../common/RouteContent";
import "./style.css"
import { UserProfile } from "../../../../utils/styled";
import userImage from "../../../../images/userImage.png";
import { ScreenSize } from "../../../../constants/ScreenSizeEnum";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { MdDelete, MdEdit } from "react-icons/md";
import LearningProfileRecordModal from "../LearningProfileDetails/LearningProfileRecordModal";
import { FirebaseContext } from "../../../Firebase";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { downloadSingleItem, injectUserStore } from "../../../../utils/helpers";
import moment from "moment";
import { PROFILE_CATEGORIES } from "../constants/learning-profile";
import { toast } from "react-toastify";
import { AttachmentNameText } from "../../common/components/AddFeedItemModal/styled";
import { Empty } from "antd";
import {
  CloudSyncOutlined,
  CrownOutlined, DeploymentUnitOutlined,
  DiffOutlined, GithubOutlined, InstagramOutlined,
  PaperClipOutlined,
  RedditOutlined,
  StarOutlined,
} from "@ant-design/icons";
import ViewstateProfileModal from "../LearningProfileDetails/ViewItemProfileModal";
import { TextButton } from "../../../common/styled-components/teacherLibraryStyledComponents";
import DeleteModalRecord from "../LearningProfileDetails/DeleteModalRecord";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { isStudent, UserRole } from "../../../../constants/UserRoleEnum";
import { ParentDashboardContext } from "../../../../contexts/ParentDashboardContext";
import ViewLearningProfileRecordModal from "../LearningProfileDetails/ViewLearningProfileRecordModal";

function StudentLearningProfileDetails({ UserStore }) {
  const [isLoading, setIsLoading] = useState(true);
  const [learningUser, setLearningProfiles] = useState(null);
  const [data, setData] = useState([]);
  const firebase = useContext(FirebaseContext);
  const [currentUser, setCurrentUser] = useState(null);
  const [editRecord, setEditRecord] = useState(null);
  const [viewFile, setViewFile] = useState(false);
  const isTeacher = UserStore.user?.role === UserRole.TEACHER;
  const isParent = UserStore.user?.role === UserRole.PARENT;

  const { studentsList, selectedStudentIndex, setSelectedStudentIndex } =
    useContext(ParentDashboardContext);

  const groupedDateByYear = data.reduce((acc, curr) => {
    const year = moment(curr.createdAt).format("YYYY");
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push({ ...curr, year });
    return acc;
  }, {} );
  const sortedData = Object.fromEntries(
    Object.entries(groupedDateByYear).sort(([yearA], [yearB]) => yearB.localeCompare(yearB))
  );
  const sortedDataArray = Object.values(sortedData);

  const { width } = useWindowDimensions();
  const isDesktopScreen = width >= ScreenSize.LG;
  const [showAddnewRecord, setShowAddnewRecord] = useState(false);
  const [activeFileRecord, setActiveFileRecord] = useState(null);
  const [showDeleteRecord, setShowDeleteRecord] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState(null);
  const [showViewRecord, setShowViewRecord] = useState(false);

  const getCurrentUser = async () => {
    try {
      const auth = getAuth()
      const user = auth.currentUser;
      setCurrentUser(user);
    } catch (error) {
      console.log(error, "getCurrentUser")
    }
  }

  const getSelectedUser = async (id) => {
    setIsLoading(true);
    try {
      const learningProfiles = collection(firebase.db, "users");
      const querySnapshot = await getDocs(learningProfiles);

      const profile = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))?.find((profile) => profile?.id === id);

      setLearningProfiles(profile);
    } catch (error) {
      console.log(error, "getSelectedUser")
    } finally {
      setIsLoading(false);
    }
  }

  const getRecords = async (id) => {
    setIsLoading(true);
    try {
      const learningProfiles = collection(firebase.db, "LearningProfiles");
      const querySnapshot = await getDocs(learningProfiles);

      const profiles = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))?.filter((profile) => profile?.userId === id);

      console.log("profiles", profiles)

      setData(profiles);
    } catch (error) {
      console.log(error, "getRecords")
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };

  const getCategoryName = (category) => {
    return PROFILE_CATEGORIES.find((cat) => cat?.value === category)?.label
  }

  const deleteRecord = async (id) => {
    try {
      const docRef = doc(firebase.db, "LearningProfiles", id);
      await deleteDoc(docRef);
      toast.success("Record deleted successfully!");
      setShowDeleteRecord(false)
      await getRecords();
    } catch (error) {
      console.error("Error deleting record:", error);
      toast.error("Failed to delete the record.");
    }
  };

  const modalTimer = () => {
    setShowViewRecord(false)
    setTimeout(() => {
      setShowViewRecord(true);
    }, 1000);
  }


  const handleEdit = (record) => {
    setEditRecord(record);
    setShowAddnewRecord(true);
  };

  const getCategoryIcon = (category) => {
    switch (category) {
      case "teacher_assistance":
        return <RedditOutlined className="icon-md icon-color ms-2 me-2" />;
      case "performance":
        return <CrownOutlined className="icon-md icon-color ms-2 me-2" />;
      case "award":
        return <StarOutlined className="icon-md icon-color ms-2 me-2" />;
      case "sharing":
        return <CloudSyncOutlined className="icon-md icon-color ms-2 me-2" />;
      case "teacher_report":
        return <DiffOutlined className="icon-md icon-color ms-2 me-2" />;
      case "creative":
        return <DeploymentUnitOutlined className="icon-md icon-color ms-2 me-2" />;
      case "leadership":
        return <GithubOutlined className="icon-md icon-color ms-2 me-2" />;
      case "social":
        return <InstagramOutlined className="icon-md icon-color ms-2 me-2" />;
      default:
        return <DiffOutlined className="icon-md icon-color ms-2 me-2" />;
    }
  };

  const onViewHandle = (record) => {
    setEditRecord(record);
    setShowViewRecord(true);
  };


  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    if (currentUser?.uid && !isParent) {
      getSelectedUser(currentUser.uid);
      getRecords(currentUser.uid);
    }

    if (isParent) {
      getSelectedUser(studentsList[selectedStudentIndex]?.uid);
      getRecords(studentsList[selectedStudentIndex]?.uid);
    }

  }, [currentUser, isParent, selectedStudentIndex]);

  return (
    <div style={{ position: "relative"}}>
      <RouteContent title="Learning Profile"></RouteContent>

      <div className="achievements-container">
        <div className="d-flex icon-profile align-items-center mb-3">
          <div className="mb-2 ms-3">
            <UserProfile
              width={isDesktopScreen ? "150px" : "90px"}
              height={isDesktopScreen ? "150px" : "90px"}
              className={"rounded-circle"}
              src={learningUser?.imageUrl || userImage}
            />
          </div>
          <div className="title-profile">
            <h3>{learningUser?.fullName || ""}</h3>
          </div>
          <div>
            {isTeacher && <button
              className="view-file-btn-main me-2 d-none d-md-block"
              onClick={() => setShowAddnewRecord(true)}
            >
              Add new
            </button>}
          </div>
        </div>
        {isTeacher && <button
          className="view-file-btn-main me-2 d-block d-md-none align-items-end"
          onClick={() => setShowAddnewRecord(true)}
        >
          Add new
        </button>}
        <div className="timeline-container">
          {data.map.length && (
            <div className="timeline">
              {data.map((month) => (
                <div key={month} className="timeline-month">
                  <div className="timeline-dot"></div>
                  <div className="timeline-line"></div>
                </div>
              ))}
            </div>
          )}
          <div className="achievements-list">
            {sortedDataArray.reverse().map((item) => (
              <div key={item.id} className="achievement-card">
                <h3 className="month-title">{item[0]?.year}</h3>
                {item.map((record) => (
                  <div key={record.id} className="month-section">
                    <div className="achievement-card" onClick={() => onViewHandle(record)}>
                      <div className="achievement-header">
                        <h4>{record.subject}</h4>
                        <span className={`achievement-status reviewed`}>
                          {getCategoryIcon(record.category)}
                          {getCategoryName(record.category)}
                        </span>
                      </div>
                      <div className="achievement-body d-flex justify-content-between flex-wrap">
                        <div className="d-flex flex-column">
                          <div className="d-flex">
                            <p>Teacher: {record.createdBy}</p>

                          </div>
                          <div className="d-flex">
                            <p>
                              Comment: {record.comment}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex flex-column mt-3">
                          {record?.attachments?.length ? record?.attachments.map((attachment) => (
                            <div>
                              <AttachmentNameText
                                style={{ cursor: "pointer", marginBottom: "5px" }}
                                  key={attachment.id}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setViewFile(true);
                                    setActiveFileRecord(attachment);
                                  }}>
                                  <PaperClipOutlined />
                                  {attachment?.name}
                                </AttachmentNameText>
                                <TextButton
                                  className={"p-1 text-purple ms-2 me-2"}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    downloadSingleItem(
                                      attachment.url,
                                      `${
                                        attachment.name
                                      }`,
                                    );
                                  }}
                                >
                                  <i className="fa fa-cloud-download-alt" />
                                </TextButton>
                              </div>

                            ),
                          ) : null}
                        </div>
                      </div>
                      <div className="achievement-actions">
                        <span className="achievement-date">{formatDate(record.createdAt)}</span>

                        {isTeacher && <div className="d-flex justify-content-center align-items-center">
                          <button
                            className="view-file-btn me-2"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEdit(record);
                            }}
                          >
                            <MdEdit color="#000" size={18} />
                          </button>
                          <button
                            className="view-file-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeleteRecordId(record.id);
                              setShowDeleteRecord(true);
                            }}
                          >
                            <MdDelete color="#000" size={18} />
                          </button>
                        </div>}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}

            {/*end clickable*/}

            {!sortedDataArray?.length && <Empty />}
          </div>
        </div>
        {showAddnewRecord &&
          <LearningProfileRecordModal
            learningUser={learningUser}
            currentUser={currentUser}
            initialData={editRecord}
            onSaved={() => getRecords()}
            toggleModal={() => setShowAddnewRecord(false)}
            isModalOpen
            closeModal={() => setShowAddnewRecord(false)} />}
        {showViewRecord &&
          <ViewLearningProfileRecordModal
            learningUser={learningUser}
            currentUser={currentUser}
            initialData={editRecord}
            modalTimer={modalTimer}
            onSaved={() => getRecords()}
            toggleModal={() => setShowViewRecord(false)}
            isModalOpen
            closeModal={() => setShowViewRecord(false)} />}

        {viewFile && <ViewstateProfileModal state={activeFileRecord} closeModal={() => setViewFile(false)} />}
        {showDeleteRecord && <DeleteModalRecord
          isOpen={showDeleteRecord}
          onConfirm={() => deleteRecord(deleteRecordId) }
          closeModal={() => setShowDeleteRecord(false)}
          toggle={() => setShowDeleteRecord(prevState => !prevState)}
        />}
      </div>
    </div>
  );
}

export default compose(inject("UserStore"), observer)(StudentLearningProfileDetails);
