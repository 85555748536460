import React, { useEffect, useState } from "react";
import {
  CustomModal,
  CustomModalBody, UserProfile,
} from "src/utils/styled";
import * as yup from "yup";
import { useContext } from "react";
import { FirebaseContext } from "src/components/Firebase";
import { toast } from "react-toastify";
import { CustomInput, CustomTextarea } from "../../common/components/ViewStudentInfoModal/LessonsInfoSection/styled";
import { CustomLabel, StudentAgeRadio } from "../../common/components/CreateGroupClassModal/styled";
import { Radio } from "antd";
import { PROFILE_CATEGORIES } from "../constants/learning-profile";
import { yupResolver } from "@hookform/resolvers/yup";

import { useForm, Controller } from "react-hook-form";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import "./style.css"
import {
 AttachmentNameText,
} from "../../common/components/AddFeedItemModal/styled";
import { downloadSingleItem, uploadFile } from "../../../../utils/helpers";
import { feedItems } from "../../../../constants/feedItemsEnum";
import userImage from "../../../../images/userImage.png";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { ScreenSize } from "../../../../constants/ScreenSizeEnum";
import {
  CloudSyncOutlined, CrownOutlined,
  DeploymentUnitOutlined,
  DiffOutlined,
  GithubOutlined,
  InstagramOutlined, PaperClipOutlined, RedditOutlined, StarOutlined,
} from "@ant-design/icons";
import { RedBarContainer, RedBarText } from "../../common/components/TeacherAbsenceRequestModal/styled";
import { TextButton } from "../../../common/styled-components/teacherLibraryStyledComponents";
import ViewstateProfileModal from "./ViewItemProfileModal";

const LearningProfileRecordSchema = yup.object().shape({
  category: yup.string().required("Category is required."),
  subject: yup.string().required("Subject is required."),
  comment: yup.string().required("Comment is required."),
  createdAt: yup.string().required("Created Date is required."),
});

const ViewLearningProfileRecordModal = ({
                                      isModalOpen,
                                      toggleModal,
                                      closeModal,
                                      learningUser,
                                      initialData = null,
                                      onSaved,
                                          modalTimer,
                                      currentUser,
                                    }) => {
  const firebase = useContext(FirebaseContext);
  const { width } = useWindowDimensions();
  const isDesktopScreen = width >= ScreenSize.LG;
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LearningProfileRecordSchema),
    defaultValues: {
      category: initialData?.category || "",
      subject: initialData?.subject || "",
      comment: initialData?.comment || "",
      createdAt: initialData?.createdAt || "",
    },
  });
  const [viewFile, setViewFile] = useState(false);
  const [activeFileRecord, setActiveFileRecord] = useState(null);

  const [attachments, setAttachments] = useState([]);


  const getUserName = () => {
    return currentUser?.displayName || currentUser?.email?.includes('admin') ? "Admin" : currentUser?.email || "Admin";
  }

  const saveToFirebase = async (data) => {
    try {

      const attachmentsList = [];
      const filePath = `${feedItems.learningProfile.type}/${learningUser.id}/`;
      for (const attachment of attachments) {
        if (attachment?.url) {
          attachmentsList.push(attachment);
          continue;
        }

        const { downloadUrl } = await uploadFile(
          firebase,
          attachment.file,
          filePath,
          attachment.id
        );
        const attachmentObj = {
          id: attachment.id,
          url: downloadUrl,
          name: attachment.file.name,
          type: attachment.file.type,
        };
        attachmentsList.push(attachmentObj);
      }


      const record = {
        ...data, updatedAt: new Date().toISOString(),
        createdBy: getUserName(),
        attachments: attachmentsList,
        userName: learningUser?.fullName || "", userId: learningUser?.id || "",
        createdAt: data?.createdAt || new Date().toISOString(),
      };


      if (initialData?.id) {
        const docRef = doc(firebase.db, "LearningProfiles", initialData.id);
        await updateDoc(docRef, record);
        await setDoc(docRef, record);
        onSaved();
        toast.success("Record updated successfully!");
      } else {
        const collectionRef = collection(firebase.db, "LearningProfiles");
        const docRef = doc(collectionRef);
        await setDoc(docRef, record);
        onSaved();
        toast.success("Record saved successfully!");
      }
    } catch (error) {
      console.error("Error saving to Firebase:", error);
      toast.error("Failed to save the record.");
    }
  };

  const getCategoryIcon = (category) => {
    switch (category) {
      case "teacher_assistance":
        return <RedditOutlined className="fs-3 icon-color" />;
      case "performance":
        return <CrownOutlined className="fs-3 icon-color" />;
      case "award":
        return <StarOutlined className="fs-3 icon-color" />;
      case "sharing":
        return <CloudSyncOutlined className="fs-3 icon-color" />;
      case "teacher_report":
        return <DiffOutlined className="fs-3 icon-color" />;
      case "creative":
        return <DeploymentUnitOutlined className="fs-3 icon-color" />;
      case "leadership":
        return <GithubOutlined className="fs-3 icon-color" />;
      case "social":
        return <InstagramOutlined className="fs-3 icon-color" />;
      default:
        return <DiffOutlined className="fs-3 icon-color" />;
    }
  };

  useEffect(() => {
    if (initialData) {
      console.log("initialData?.category", initialData?.category)
      reset({
        category: initialData?.category || "",
        subject: initialData?.subject || "",
        comment: initialData?.comment || "",
        createdAt: initialData?.createdAt || "",
      });
      setAttachments(initialData?.attachments || []);
    }
  }, [initialData]);

  return (
    <div>
      <CustomModal
        padding="0px"
        centered
        size="lg"
        isOpen={isModalOpen}
        toggle={toggleModal}

      >
        <CustomModalBody className="position-relative overflow-hidden" padding="0px">
          <div className="d-flex justify-content-between align-items-center mb-3 ms-3 mt-3 ">
            <div className="d-flex mb-2 ms-3 align-items-center">
              <UserProfile
                width={isDesktopScreen ? "150px" : "90px"}
                height={isDesktopScreen ? "150px" : "90px"}
                className={"rounded-circle"}
                src={learningUser?.imageUrl || userImage}
              />
              <div className="me-3 ms-3">
                <h3>{learningUser?.fullName || ""}</h3>
              </div>
            </div>
            <div className="position-absolute close-btn-container bg-white rounded-circle" >
              <button
                onClick={() => {
                  closeModal();
                }}
                className={"close border-0 outline-0"}
              >
                <i className="fa fa-times" />
              </button>
            </div>
          </div>
          <div className="d-flex">
            <RedBarContainer>
              <RedBarText>View Details</RedBarText>
            </RedBarContainer>
            <form className="pt-2 mb-4 px-4 pb-4">
              <div className="InputGroup mb-2">
                <div className="inputContainer w-100 d-flex flex-column">
                  <CustomLabel className="ms-0"><h5 className="fw-bold">Subject</h5></CustomLabel>
                  <Controller
                    name="subject"
                    control={control}
                    render={({ field }) => (
                      <div className="full-width">
                        <CustomInput
                          {...field}
                          disabled
                          className="InputField ms-0 d-none d-md-block"
                          type="text"
                          placeholder="Enter subject"
                        />
                        <CustomTextarea
                          {...field}
                          disabled
                          className="InputField ms-0 d-block d-md-none"
                          placeholder="Enter subject..."
                        />
                        {errors.subject && (
                          <p className="text-danger">{errors.subject.message}</p>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>

              <div className="mb-4">
                <CustomLabel><h5 className="fw-bold">Categories</h5></CustomLabel>
                <div className="achievement-card-details-view">
                  <Controller
                    name="category"
                    control={control}
                    render={({ field }) => (
                      <div className="row">
                        <Radio.Group
                          disabled
                          className="row"
                          value={field.value}
                          onChange={e => {
                            field.onChange(e.target.value);
                          }}>
                          {PROFILE_CATEGORIES.map((category) => (
                            <div className="col col-md-6">
                              <StudentAgeRadio
                                key={category.value}
                                value={category.value}
                                className={`${field.value === category.value ? "active-category" : ""}`}
                              >
                                <div className={`ms-2 me-2 d-flex align-items-center ${field.value === category.value ? "active-category" : ""}`}>
                                  {getCategoryIcon(category.value)}
                                  <span className="ms-2 me-2 d-flex"> {category.label}</span>
                                </div>
                              </StudentAgeRadio>
                            </div>
                          ))}
                        </Radio.Group>
                        {errors.category && (
                          <p className="text-danger">{errors.category.message}</p>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>

              <div className="InputGroup mb-4">
                  <div className="inputContainer w-50">
                    <label htmlFor="birthday ms-0" style={{ marginLeft: 0 }}>
                      <h5 className="fw-bold">Date</h5>
                    </label>
                    <Controller
                      name="createdAt"
                      control={control}
                      render={({ field }) => (
                        <div className="full-width">
                          <input
                            className="InputField ms-0"
                            label={"Created Date"}
                            name="createdAt"
                            placeholder="Created Date"
                            type="date"
                            disabled
                            {...field}
                            noValidate
                          />
                          {errors.createdAt && (
                            <p className="text-danger">{errors.createdAt.message}</p>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </div>

              <div className="InputGroup mb-2 mb-4">
                <div className="inputContainer w-100">
                  <CustomLabel className="ms-0"><h5 className="fw-bold">Comment</h5></CustomLabel>
                  <Controller
                    name="comment"
                    control={control}
                    render={({ field }) => (
                      <div className="full-width">
                        <CustomTextarea
                          {...field}
                          disabled
                          className="InputField ms-0"
                          placeholder="Enter comment..."
                        />
                        {errors.comment && (
                          <p className="text-danger">{errors.comment.message}</p>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>


              {attachments.length ? (<div className="d-flex justify-content-between">
                <CustomLabel className="ms-0"><h5 className="fw-bold">Uploaded Files</h5></CustomLabel>
              </div>) : null}

              {attachments.length ? (
                <div className="uploaded-box">
                  {attachments.map((attachment) => (
                    <div>
                      <AttachmentNameText
                        style={{ cursor: "pointer", marginBottom: "5px" }}
                        key={attachment.id}
                        onClick={() => {
                          setViewFile(true);
                          setActiveFileRecord(attachment);
                        }}>
                        <PaperClipOutlined />
                        {attachment?.name}
                      </AttachmentNameText>
                      <TextButton
                        className={"p-1 text-purple ms-2 me-2"}
                        onClick={() => {
                          modalTimer()
                          setTimeout(() => {
                            downloadSingleItem(
                              attachment.url,
                              `${
                                attachment.name
                              }`,
                            );
                          }, 111)
                        }}
                      >
                        <i className="fa fa-cloud-download-alt" />
                      </TextButton>
                    </div>
                  ))}
                </div>
              ) : null}

              <div className="d-flex justify-content-center mt-5">
                <div className="flex-row d-flex">
                  <button onClick={closeModal} type="submit"
                          className="submitbutton h5 px-4 py-1 form-btn btn-success-custom">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </CustomModalBody>
        {viewFile && <ViewstateProfileModal state={activeFileRecord} closeModal={() => setViewFile(false)} />}
      </CustomModal>
    </div>
  );
};

export default ViewLearningProfileRecordModal;
