import React, { useEffect, useRef, useState } from "react";
import {
  CustomModal,
  CustomModalBody, UserProfile,
} from "src/utils/styled";
import * as yup from "yup";
import { useContext } from "react";
import { FirebaseContext } from "src/components/Firebase";
import { toast } from "react-toastify";
import { CustomInput, CustomTextarea } from "../../common/components/ViewStudentInfoModal/LessonsInfoSection/styled";
import { CustomLabel, StudentAgeRadio } from "../../common/components/CreateGroupClassModal/styled";
import { Radio } from "antd";
import { PROFILE_CATEGORIES } from "../constants/learning-profile";
import { yupResolver } from "@hookform/resolvers/yup";

import { useForm, Controller } from "react-hook-form";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import "./style.css"
import {
  AttachmentName,
  UploadAttachmentContainer,
  UploadIcon,
  UploadPlaceholder,
} from "../../common/components/AddFeedItemModal/styled";
import { isFileValidated, uploadFile } from "../../../../utils/helpers";
import { v4 as uuidv4 } from "uuid";
import { feedItems } from "../../../../constants/feedItemsEnum";
import { RequiredInputSpan } from "../../../../utils/shared/styled";
import userImage from "../../../../images/userImage.png";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { ScreenSize } from "../../../../constants/ScreenSizeEnum";
import {
  CloudSyncOutlined, CrownOutlined,
  DeploymentUnitOutlined,
  DiffOutlined,
  GithubOutlined,
  InstagramOutlined, RedditOutlined, StarOutlined,
} from "@ant-design/icons";
import { RedBarContainer, RedBarText } from "../../common/components/TeacherAbsenceRequestModal/styled";

const LearningProfileRecordSchema = yup.object().shape({
  category: yup.string().required("Category is required."),
  subject: yup.string().required("Subject is required."),
  comment: yup.string().required("Comment is required."),
  createdAt: yup.string().required("Created Date is required."),
});

const LearningProfileRecordModal = ({
                                      isModalOpen,
                                      toggleModal,
                                      closeModal,
                                      learningUser,
                                      initialData = null,
                                      onSaved,
                                      currentUser,
                                    }) => {
  const firebase = useContext(FirebaseContext);
  const { width } = useWindowDimensions();
  const isDesktopScreen = width >= ScreenSize.LG;
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LearningProfileRecordSchema),
    defaultValues: {
      category: initialData?.category || "",
      subject: initialData?.subject || "",
      comment: initialData?.comment || "",
      createdAt: initialData?.createdAt || "",
    },
  });
  const uploadRef = useRef();
  const [uploading, setUploading] = useState(false);

  const [attachments, setAttachments] = useState([]);


  const getUserName = () => {
    return currentUser?.displayName || currentUser?.email?.includes('admin') ? "Admin" : currentUser?.email || "Admin";
  }

  const handleDeleteAttachment = (id) => {
    setAttachments((prevState) => prevState.filter((attachment) => attachment.id !== id));
  };

  const saveToFirebase = async (data) => {
    try {

      const attachmentsList = [];
      const filePath = `${feedItems.learningProfile.type}/${learningUser.id}/`;
      for (const attachment of attachments) {
        if (attachment?.url) {
          attachmentsList.push(attachment);
          continue;
        }

        const { downloadUrl } = await uploadFile(
          firebase,
          attachment.file,
          filePath,
          attachment.id
        );
        const attachmentObj = {
          id: attachment.id,
          url: downloadUrl,
          name: attachment.file.name,
          type: attachment.file.type,
        };
        attachmentsList.push(attachmentObj);
      }


      const record = {
        ...data, updatedAt: new Date().toISOString(),
        createdBy: getUserName(),
        attachments: attachmentsList,
        userName: learningUser?.fullName || "", userId: learningUser?.id || "",
        createdAt: data?.createdAt || new Date().toISOString(),
      };


      if (initialData?.id) {
        const docRef = doc(firebase.db, "LearningProfiles", initialData.id);
        await updateDoc(docRef, record);
        await setDoc(docRef, record);
        onSaved();
        toast.success("Record updated successfully!");
      } else {
        const collectionRef = collection(firebase.db, "LearningProfiles");
        const docRef = doc(collectionRef);
        await setDoc(docRef, record);
        onSaved();
        toast.success("Record saved successfully!");
      }
    } catch (error) {
      console.error("Error saving to Firebase:", error);
      toast.error("Failed to save the record.");
    }
  };

  const onSubmit = async (data) => {
    await saveToFirebase(data);
    reset();
    closeModal();
  };

  const getCategoryIcon = (category) => {
    switch (category) {
      case "teacher_assistance":
        return <RedditOutlined className="fs-3 icon-color" />;
      case "performance":
        return <CrownOutlined className="fs-3 icon-color" />;
      case "award":
        return <StarOutlined className="fs-3 icon-color" />;
      case "sharing":
        return <CloudSyncOutlined className="fs-3 icon-color" />;
      case "teacher_report":
        return <DiffOutlined className="fs-3 icon-color" />;
      case "creative":
        return <DeploymentUnitOutlined className="fs-3 icon-color" />;
      case "leadership":
        return <GithubOutlined className="fs-3 icon-color" />;
      case "social":
        return <InstagramOutlined className="fs-3 icon-color" />;
      default:
        return <DiffOutlined className="fs-3 icon-color" />;
    }
  };

  useEffect(() => {
    if (initialData) {
      console.log("initialData?.category", initialData?.category)
      reset({
        category: initialData?.category || "",
        subject: initialData?.subject || "",
        comment: initialData?.comment || "",
        createdAt: initialData?.createdAt || "",
      });
      setAttachments(initialData?.attachments || []);
    }
  }, [initialData]);

  return (
    <div>
      <CustomModal
        padding="0px"
        centered
        size="lg"
        isOpen={isModalOpen}
        toggle={toggleModal}

      >
        <CustomModalBody className="position-relative overflow-hidden" padding="0px">
          <div className="d-flex justify-content-between align-items-center mb-3 ms-3 mt-3 ">
            <div className="d-flex mb-2 ms-3 align-items-center">
              <UserProfile
                width={isDesktopScreen ? "150px" : "90px"}
                height={isDesktopScreen ? "150px" : "90px"}
                className={"rounded-circle"}
                src={learningUser?.imageUrl || userImage}
              />
              <div className="me-3 ms-3">
                <h3>{learningUser?.fullName || ""}</h3>
              </div>
            </div>
            <div className="position-absolute close-btn-container bg-white rounded-circle" >
              <button
                onClick={() => {
                  closeModal();
                }}
                className={"close border-0 outline-0"}
              >
                <i className="fa fa-times" />
              </button>
            </div>
          </div>
          <div className="d-flex">
            <RedBarContainer>
              <RedBarText>Add New</RedBarText>
            </RedBarContainer>
            <form className="pt-2 mb-4 px-4 pb-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4">
                <CustomLabel><h5 className="fw-bold">Categories</h5></CustomLabel>
                <div className="achievement-card-details">
                  <Controller
                    name="category"
                    control={control}
                    render={({ field }) => (
                      <div className="row">
                        <Radio.Group
                          className="row"
                          value={field.value}
                          onChange={e => {
                            field.onChange(e.target.value);
                          }}>
                          {PROFILE_CATEGORIES.map((category) => (
                            <div className="col col-md-6">
                              <StudentAgeRadio
                                key={category.value}
                                value={category.value}
                              >
                                <div className="ms-2 me-2 d-flex align-items-center">
                                  {getCategoryIcon(category.value)}
                                  <span className="ms-2 me-2 d-flex"> {category.label}</span>
                                </div>
                              </StudentAgeRadio>
                            </div>
                          ))}
                        </Radio.Group>
                        {errors.category && (
                          <p className="text-danger">{errors.category.message}</p>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>

              <div className="InputGroup">
                <div className="inputContainer w-100">
                  <label htmlFor="birthday ms-0" style={{ marginLeft: 0 }}>
                    <h5 className="fw-bold">Created Date</h5>
                  </label>
                  <Controller
                    name="createdAt"
                    control={control}
                    render={({ field }) => (
                      <div className="full-width">
                        <input
                          className="InputField ms-0"
                          label={"Created Date"}
                          name="createdAt"
                          placeholder="Created Date"
                          type="date"
                          {...field}
                          noValidate
                        />
                        {errors.createdAt && (
                          <p className="text-danger">{errors.createdAt.message}</p>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>

              <div className="InputGroup mb-2">
                <div className="inputContainer w-100">
                  <CustomLabel className="ms-0"> <h5 className="fw-bold">Subject</h5></CustomLabel>
                  <Controller
                    name="subject"
                    control={control}
                    render={({ field }) => (
                      <div className="full-width">
                        <CustomInput
                          {...field}
                          className="InputField ms-0"
                          type="text"
                          placeholder="Enter subject"
                        />
                        {errors.subject && (
                          <p className="text-danger">{errors.subject.message}</p>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="InputGroup mb-2 mb-4">
                <div className="inputContainer w-100">
                  <CustomLabel className="ms-0"><h5 className="fw-bold">Comment</h5></CustomLabel>
                  <Controller
                    name="comment"
                    control={control}
                    render={({ field }) => (
                      <div className="full-width">
                        <CustomTextarea
                          {...field}
                          className="InputField ms-0"
                          placeholder="Enter comment..."
                        />
                        {errors.comment && (
                          <p className="text-danger">{errors.comment.message}</p>
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>


              <div className="d-flex justify-content-between">
                <CustomLabel className="ms-0"><h5 className="fw-bold">Upload File</h5></CustomLabel>
                <span>(PDF, JPEG, PNG, Video)</span>
              </div>
              <input
                ref={uploadRef}
                accept="image/png, image/jpeg, application/pdf, video/mp4,video/x-m4v,video/*"
                hidden
                type="file"
                onChange={async (e) => {
                  const file = e.target.files[0];
                  if (isFileValidated(file)) {
                    setUploading(true);

                    const attachmentObj = {
                      id: uuidv4(),
                      file,
                    };
                    const updatedAttachments = [...attachments, attachmentObj];
                    // onAttachmentsChange(updatedAttachments);
                    setAttachments(prevState => [...prevState, attachmentObj]);
                    setUploading(false);
                  } else {
                    toast.error("File Not Supported");
                  }
                }}
              />

              <UploadAttachmentContainer
                onClick={() => uploadRef.current.click()}
              >
                <UploadPlaceholder>Upload</UploadPlaceholder>
                <UploadIcon width={30} height={30} />
              </UploadAttachmentContainer>
              {attachments.length ? (
                <div className="mt-3">
                  {attachments.map((attachment) => (
                    <div key={attachment.id} className="d-flex align-items-center justify-content-between mb-2">
                      <AttachmentName>{attachment.file?.name || attachment?.name}</AttachmentName>
                      <button
                        className="btn btn-link text-danger"
                        onClick={() => handleDeleteAttachment(attachment.id)}
                      >
                        Delete
                      </button>
                    </div>
                  ))}
                </div>
              ) : null}

              <div className="d-flex justify-content-end mt-5">
                <div className="flex-row d-flex">
                  <button type="submit" className="cancelbutton h5 px-4 py-1 form-btn btn-cancel-custom">
                    Cancel
                  </button>
                  <div className="line-btn"/>
                  <button type="submit" className="submitbutton h5 px-4 py-1 form-btn btn-success-custom">
                    {initialData ? "Update" : "Comfirm"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </CustomModalBody>
      </CustomModal>
    </div>
  );
};

export default LearningProfileRecordModal;
